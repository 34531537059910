<template>
  <v-container>
    <v-card class="pa-2" color="#E0F5F5" width="100%" elevation="0">
      <v-expand-transition>
        <v-card-text
          style="color: black"
          v-if="expanded"
        >
          <v-row class="move-in-down-arrow" no-gutters>
            <div>
              <span class="hb-font-header-3-medium">Move-In Date:&nbsp;</span>
              <span class="hb-font-header-3">
                {{
                  summary_data.start_date | formatDateTimeCustom("MMM DD, YYYY")
                }}
              </span>
            </div>
            <HbBtn
              v-if="expanded"
              icon
              @click="toggleExpansion"
              :mdi-code="iconCode"
            />
          </v-row>
          <div class="overall-bill-split">
          <template v-if="summary_invoice && summary_invoice.InvoiceLines">
            <v-row
              v-for="(line, i) in summary_invoice.InvoiceLines"
              :key="'rent'+ i"
              no-gutters
              class="hb-font-body"
            >
              <template v-if="line.Product.default_type == 'rent'">
                <v-col>
                  {{ line.Product.name }}
                  <small
                    class="hb-text-light"
                    v-if="line.Service && line.Service.recurring"
                    >({{ line.start_date | formatDate }} -
                    {{ line.end_date | formatDate }})</small
                  >
                </v-col>
                <v-col class="align-right">
                  <span v-if="line.qty > 1"> {{ line.qty }} x </span>
                  {{ line.cost | formatMoney }}
                  <span
                    v-if="
                      (line.Product.default_type === 'product' ||
                        line.Product.default_type === 'late') &&
                      step > 2 &&
                      !invoices[0]?.id
                    "
                  >
                  </span>
                </v-col>
              </template>
            </v-row>
          </template>
          <template v-if="summary_invoice && summary_invoice.InvoiceLines">
            <v-row
              v-for="(line, i) in summary_invoice.InvoiceLines"
              :key="'services'+ i"
              no-gutters
              class="hb-font-body"
            >
              <template v-if="line.Product.default_type != 'rent'">
                <v-col>
                  {{
                    line.Product.default_type == "insurance"
                      ? "Coverage (" + line.Product.name + ")"
                      : line.Product.name
                  }}
                  <small v-if="line.Service && line.Service.recurring"
                    >{{ line.start_date | formatDate }} -
                    {{ line.end_date | formatDate }}</small
                  >
                </v-col>
                <v-col class="align-right">
                  <span v-if="line.qty > 1"> {{ line.qty }} x </span>
                  {{ line.cost | formatMoney }}
                  <span
                    v-if="
                      (line.Product.default_type === 'product' ||
                        line.Product.default_type === 'late') &&
                      step > 2 &&
                      !invoices[0]?.id
                    "
                  >
                  </span>
                </v-col>
              </template>
            </v-row>
          </template>
          <div class="hb-font-body" v-if="summary_invoice">
            <v-row class="hb-font-body-medium" no-gutters>
              <v-col>Subtotal</v-col>
              <v-col class="align-right">{{
                summary_invoice.sub_total | formatMoney
              }}</v-col>
            </v-row>

            <v-row no-gutters>
              <v-col>Tax</v-col>
              <v-col class="align-right">{{
                summary_invoice.total_tax | formatMoney
              }}</v-col>
            </v-row>

            <v-row no-gutters v-if="summary_invoice.discounts > 0">
              <v-col>Discounts</v-col>
              <v-col class="align-right"
                >-{{ summary_invoice.discounts | formatMoney }}</v-col
              >
            </v-row>

          </div>
          </div>
        </v-card-text>
      </v-expand-transition>
      <v-card-title class="total-balance-due-section" :style="expanded?'box-shadow: rgba(71, 84, 116, 0.1) 0px -4px 8px 0px;':''">
        <v-row no-gutters>
          <v-col>
            <div class="lease-amount-flex hb-font-header-3-medium">
              <span>Move-In Cost:</span>
              <span>{{ summary_invoice.balance | formatMoney }}</span>
            </div>
            <div class="lease-amount-flex hb-font-body">
              <span>Monthly Rent:</span>
              <span>{{ summary_data.rent | formatMoney }}</span>
            </div>
          </v-col>
          <HbBtn
            v-if="!expanded"
            icon
            @click="toggleExpansion"
            :mdi-code="iconCode"
          />
        </v-row>
      </v-card-title>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import HbExpansionPanel from "../../../aviary/HbExpansionPanel.vue";

export default {
  components: { HbExpansionPanel },
  name: "LeaseTotalOverlay",
  props: ["step"],
  data() {
    return {
      invoices: [],
      expanded: false,
    };
  },
  computed: {
    ...mapGetters({
      configuration_unit: "onBoardingStore/configuration_unit",
      configuration_lease: "onBoardingStore/configuration_lease",
      configuration_invoices: "onBoardingStore/configuration_invoices",
      leasesArray: 'paymentsStore/getLeases',
      invoice: "onBoardingStore/invoices",
      unit: "onBoardingStore/unit",
      lease: "onBoardingStore/lease",
      reservation: 'onBoardingStore/reservation',
    }),
    summary_data() {
      return this.lease?.id ? this.lease : this.configuration_lease;
    },
    summary_invoice() {

      let summary_invoice;
      // if((this.step != 1 || this.lease?.id) && !this.reservation?.id){
      //   this.invoices = this.invoice;
      // }else{
      //   this.invoices = this.configuration_invoices;
      // }
      // this.invoices = this.configuration_invoices;

      // if (this.step == 1) {
      //   this.invoices = this.configuration_invoices;
      // } else {
      //   this.invoices = this.invoice;
      // }
      //let currentRent;
      if (this.invoices && this.invoices.length) {
        for (const invoice of this.invoices) {
          if (!summary_invoice) {
            summary_invoice = JSON.parse(JSON.stringify(invoice));
            continue;
          }

          for (const line of invoice.InvoiceLines) {
            console.log("line", line);
            let index = summary_invoice.InvoiceLines.findIndex(
              (il) => il.Product.id == line.Product.id
            );
            if (index >= 0) {
              if (
                moment(line.end_date, "YYYY-MM-DD").isAfter(
                  moment(
                    summary_invoice.InvoiceLines[index].end_date,
                    "YYYY-MM-DD"
                  )
                )
              ) {
                summary_invoice.InvoiceLines[index].end_date = line.end_date;
              }
              summary_invoice.InvoiceLines[index].cost += line.cost;
            } else {
              summary_invoice.InvoiceLines.push(
                JSON.parse(JSON.stringify(line))
              );
            }
          }
          summary_invoice.sub_total += invoice.sub_total;
          summary_invoice.total_tax += invoice.total_tax;
          summary_invoice.discounts += invoice.discounts;
          summary_invoice.total_due += invoice.total_due;
          summary_invoice.balance += invoice.balance;
        }
      }

      return summary_invoice || { InvoiceLines: [] };
    },
    iconCode() {
      if (this.expanded) {
        return "mdi-chevron-down";
      } else {
        return "mdi-chevron-up";
      }
    },
  },
  methods: {
    toggleExpansion() {
      this.expanded = !this.expanded;
    },
  },
  watch:{
    configuration_invoices:{
      handler(newVal) {
        this.invoices = newVal?.length > 0 ? newVal : this.invoice;
        if(this.step == 3 && this.leasesArray?.length){
          this.invoices = this.leasesArray[0]?.AdvanceInvoices
        }
        // this.invoices = newVal;
      },
      deep: true
    },
    invoice:{
      handler(newVal) {
        this.invoices = newVal?.length > 0 ? newVal : this.configuration_invoices;
        if(this.step == 3 && this.leasesArray?.length){
          this.invoices = this.leasesArray[0].AdvanceInvoices
        }
      },
      deep: true
    },
  },
  created() {
    this.invoices = this.configuration_invoices || this.invoice
  },
};
</script>

<style>
/* Custom CSS to make expansion panel expand from bottom to top */
.bottom-to-top {
  transform-origin: top center;
}

.drop-up-button-align {
  display: flex;
  justify-content: flex-end;
}

.block-card {
  position: fixed;
  bottom: 20px;
}

.lease-amount-flex {
  display: flex;
  justify-content: space-between;
}

.total-balance-due-section {
  height: 104px;
  }

.overall-bill-split {
  overflow: auto;
  max-height: 314px;
}

.overall-bill-split::-webkit-scrollbar {
  width: 8px;
}

.overall-bill-split::-webkit-scrollbar-thumb {
  width: 8px;
  background: #637381;
  border-radius: 100px;
}

.move-in-down-arrow {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px -4px rgba(71, 84, 116, 0.1) !important;
  position: sticky;
  top: 0;
  z-index: 1;
}

.scrollable-content {
  max-height: calc(100%); /* Adjust as needed */
  overflow-y: auto;
}
</style>
