<template>
  <div class="business-info-wrapper">
    <v-expansion-panels v-model="panel" multiple>
      <hb-expansion-panel :topMargin="false">
        <template v-slot:title>Business Information</template>
        <template v-slot:content>
          <template v-if="isRegisteredBusiness">
            <div class="px-6 py-4">
              <p class="top-header">
                We need to collect information about the registered legal entity you are onboarding with our payment service provider.
              </p>
            </div>
            <v-divider></v-divider>
            <hb-form
              label="Legal Entity Name"
              description="Provide the legal name of your business as shown on your articles of incorporation or documents registering your business with the government."
              required
              extraLarge
            >
              <div class="fields-wrapper">
                <v-row>
                  <v-col class="py-0 my-0">
                    <HbTextField
                      v-model="form.business_information.legal_name"
                      v-validate.disable="'required'"
                      placeholder="Business Name"
                      data-vv-name="legal_name"
                      data-vv-as="Legal Entity Name"
                      data-vv-scope="business_info"
                      :error="
                        errors.collect('business_info.legal_name').length > 0
                      "
                    />
                  </v-col>
                </v-row>
              </div>
            </hb-form>
            <hb-form
              label="Legal Entity Address"
              description="Provide the legal address as shown on articles of incorporation or documents registering your business with the government."
              required
              full
              extraLarge
            >
              <div class="fields-wrapper">
                <v-row>
                  <v-col class="py-0 my-0">
                    <HbTextField
                      v-model="form.business_information.legal_address.address"
                      v-validate.disable="{ required: true, customAddressValidation: true }"
                      placeholder="Address (Line 1)"
                      data-vv-name="legal_address1"
                      data-vv-as="Address 1"
                      data-vv-scope="business_info"
                      :error="
                        errors.collect('business_info.legal_address1').length >
                        0
                      "
                      full
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0 my-0">
                    <HbTextField
                      v-model="form.business_information.legal_address.address2"
                      v-validate.disable="{ customAddressValidation: true }"
                      placeholder="Address (Line 2)"
                      data-vv-name="legal_address2"
                      data-vv-as="Address 2"
                      data-vv-scope="business_info"
                      :error="
                        errors.collect('business_info.legal_address2').length >
                        0
                      "
                      full
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="py-0 my-0">
                    <HbTextField
                      v-model="form.business_information.legal_address.city"
                      v-validate.disable="'required'"
                      placeholder="City"
                      data-vv-name="legal_city"
                      data-vv-as="City"
                      data-vv-scope="business_info"
                      :error="
                        errors.collect('business_info.legal_city').length > 0
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="py-0 my-0">
                    <HbSelect
                      v-model="form.business_information.legal_address.state"
                      :items="states"
                      item-text="value"
                      item-value="value"
                      v-validate.disable="'required'"
                      placeholder="State/Province"
                      :clearable="false"
                      data-vv-name="legal_state"
                      data-vv-as="State"
                      data-vv-scope="business_info"
                      :error="
                        errors.collect('business_info.legal_state').length > 0
                      "
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="py-0 my-0">
                    <HbAutocomplete
                      v-model="form.business_information.legal_address.country"
                      :items="countryCodes"
                      v-validate.disable="'required'"
                      placeholder="Country"
                      :clearable="false"
                      data-vv-name="legal_country"
                      data-vv-as="Country"
                      data-vv-scope="business_info"
                      :error="
                        errors.collect('business_info.legal_country').length > 0
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="py-0 my-0">
                    <HbTextField
                      v-model="form.business_information.legal_address.zip"
                      v-validate.disable="'required|max:5'"
                      placeholder="Zip/Postal Code"
                      v-mask="'#####'"
                      data-vv-name="legal_zip"
                      data-vv-as="Zip"
                      data-vv-scope="business_info"
                      :error="
                        errors.collect('business_info.legal_zip').length > 0
                      "
                    />
                  </v-col>
                </v-row>
              </div>
            </hb-form>
            <!-- <hb-form
              label="Registered Business Contact Number"
              description="Create a quick description then connect with the copy writer to finalize copy."
              required
              full
              extraLarge
            >
              <div class="fields-wrapper">
                <v-row>
                  <v-col cols="3" class="py-0 my-0">
                    <HbSelect
                      v-model="
                        form.business_information.business_phone.country_code
                      "
                      :items="codes"
                      placeholder="Country Code"
                      :clearable="false"
                      data-vv-name="business_phone_code"
                      data-vv-as="Code"
                      data-vv-scope="business_info"
                      :error="
                        errors.collect('business_info.business_phone_code')
                          .length > 0
                      "
                    >
                      <template v-slot:prepend-inner> + </template>
                      <template v-slot:item="data"> +{{ data.item }} </template>
                    </HbSelect>
                  </v-col>
                  <v-col cols="6" class="py-0 my-0">
                    <HbTextField
                      v-model="form.business_information.business_phone.phone"
                      v-validate="'required|length:14'"
                      v-mask="'(###) ###-####'"
                      placeholder="Number"
                      data-vv-name="business_phone_number"
                      data-vv-as="Number"
                      data-vv-scope="business_info"
                      :error="
                        errors.collect('business_info.business_phone_number')
                          .length > 0
                      "
                      full
                    />
                  </v-col>
                  <v-col cols="3" class="py-0 my-0">
                    <HbTextField
                      v-model="
                        form.business_information.business_phone.extension
                      "
                      placeholder="Extension"
                      data-vv-name="business_phone_extension"
                      data-vv-as="Extension"
                      data-vv-scope="business_info"
                      :error="
                        errors.collect('business_info.business_phone_extension')
                          .length > 0
                      "
                    />
                  </v-col>
                </v-row>
              </div>
            </hb-form> -->
            <hb-form
              label="Tax Identification Number (EIN or ITIN)"
              description="Your tax identification number is required as per Internal Revenue Code (IRC) Section 6050W."
              required
              extraLarge
            >
              <div class="fields-wrapper">
                <HbTextField
                  v-model="form.business_information.ein"
                  v-validate.disable="'required|length:10'"
                  v-mask="'##-#######'"
                  placeholder="Enter EIN or ITIN"
                  data-vv-name="ein"
                  data-vv-as="EIN"
                  data-vv-scope="business_info"
                  :error="errors.collect('business_info.ein').length > 0"
                  full
                />
              </div>
            </hb-form>
            <hb-form
              label="Business Email"
              description="This email address must be unique and not associated with any other Tenant Payments account."
              required
              extraLarge
            >
              <div class="fields-wrapper">
                <HbTextField
                  v-model="form.business_information.business_email"
                  v-validate.disable="'required|email'"
                  placeholder="Enter Email"
                  data-vv-name="email"
                  data-vv-as="Email"
                  data-vv-scope="business_info"
                  :error="errors.collect('business_info.email').length > 0"
                  full
                  @input="lowercaseInput"
                />
              </div>
            </hb-form>
          </template>
          <div class="px-6 py-4">
            <p class="top-header">
              We need to collect the DBA (Doing Business As) information about
              your business as it's registered with your state. If you have not
              registered your DBA with the state, provide it as it is shown on
              your webpage or presented to your customers.
            </p>
          </div>
          <v-divider></v-divider>
          <div class="fields-wrapper">
            <hb-form label="DBA Name" required extraLarge>
              <HbTextField
                v-model="form.business_information.dba"
                v-validate.disable="'required'"
                placeholder="Enter Name"
                data-vv-name="dba"
                data-vv-as="Doing Business As"
                data-vv-scope="business_info"
                :error="errors.collect('business_info.dba').length > 0"
              />
            </hb-form>
          </div>
          <hb-form label="Website URL" required extraLarge>
            <div class="fields-wrapper">
              <HbTextField
                v-model="form.business_information.website"
                v-validate="'required'"
                placeholder="Enter URL"
                data-vv-name="website"
                data-vv-as="Website URL"
                data-vv-scope="business_info"
                :error="errors.collect('business_info.website').length > 0"
              />
            </div>
          </hb-form>
          <hb-form
            label="Storage Facility Address"
            description="Enter a valid address."
            required
            full
            extraLarge
          >
            <div class="fields-wrapper">
              <v-row>
                <v-col class="py-0 my-0">
                  <HbTextField
                    v-model="form.business_information.address"
                    v-validate.disable="{ required: true, customAddressValidation: true }"
                    placeholder="Address (Line 1)"
                    data-vv-name="storage_facility_address1"
                    data-vv-as="Address 1"
                    data-vv-scope="business_info"
                    :error="
                      errors.collect('business_info.storage_facility_address1')
                        .length > 0
                    "
                    full
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0 my-0">
                  <HbTextField
                    v-model="form.business_information.address2"
                    v-validate.disable="{ customAddressValidation: true }"
                    placeholder="Address (Line 2)"
                    data-vv-name="storage_facility_address2"
                    data-vv-as="Address 2"
                    data-vv-scope="business_info"
                    :error="
                      errors.collect('business_info.storage_facility_address2')
                        .length > 0
                    "
                    full
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0 my-0">
                  <HbTextField
                    v-model="form.business_information.city"
                    v-validate.disable="'required'"
                    placeholder="City"
                    data-vv-name="storage_facility_city"
                    data-vv-as="City"
                    data-vv-scope="business_info"
                    :error="
                      errors.collect('business_info.storage_facility_city')
                        .length > 0
                    "
                  />
                </v-col>
                <v-col cols="6" class="py-0 my-0">
                  <HbSelect
                    v-model="form.business_information.state"
                    :items="states"
                    item-text="value"
                    item-value="value"
                    v-validate.disable="'required'"
                    placeholder="State/Province"
                    :clearable="false"
                    data-vv-name="storage_facility_state"
                    data-vv-as="State"
                    data-vv-scope="business_info"
                    :error="
                      errors.collect('business_info.storage_facility_state')
                        .length > 0
                    "
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0 my-0">
                  <HbAutocomplete
                    v-model="form.business_information.country"
                    :items="countryCodes"
                    v-validate.disable="'required'"
                    placeholder="Country"
                    :clearable="false"
                    data-vv-name="storage_facility_country"
                    data-vv-as="Country"
                    data-vv-scope="business_info"
                    :error="
                      errors.collect('business_info.storage_facility_country')
                        .length > 0
                    "
                  />
                </v-col>
                <v-col cols="6" class="py-0 my-0">
                  <HbTextField
                    v-model="form.business_information.zip"
                    v-validate.disable="'required|max:5'"
                    placeholder="Zip/Postal Code"
                    v-mask="'#####'"
                    data-vv-name="storage_facility_zip"
                    data-vv-as="Zip"
                    data-vv-scope="business_info"
                    :error="
                      errors.collect('business_info.storage_facility_zip')
                        .length > 0
                    "
                  />
                </v-col>
              </v-row>
              <v-row>
                  <v-col cols="6" class="py-0 my-0">
                    
                    <HbSelect
                    v-model="form.business_information.time_zone"
                    :items="time_zone"
                    v-validate.disable="'required'"
                    placeholder="Time Zone"
                    :clearable="false"
                    data-vv-name="storage_facility_time_zone"
                    data-vv-as="Time Zone"
                    data-vv-scope="business_info"
                    :error="
                      errors.collect('business_info.storage_facility_time_zone')
                        .length > 0
                    "
                  />
                  </v-col>
                </v-row>

            </div>
          </hb-form>
          <hb-form
            label="Storage Facility Phone Number"
            required
            full
            extraLarge
          >
            <div class="fields-wrapper">
              <v-row>
                <v-col cols="3" class="py-0 my-0">
                  <HbSelect
                    v-model="
                      form.business_information.facility_phone.country_code
                    "
                    :items="codes"
                    placeholder="Country Code"
                    :clearable="false"
                    data-vv-name="storage_facility_phone_code"
                    data-vv-as="Code"
                    data-vv-scope="business_info"
                    :error="
                      errors.collect(
                        'business_info.storage_facility_phone_code'
                      ).length > 0
                    "
                  >
                    <template v-slot:prepend-inner> + </template>
                    <template v-slot:item="data"> +{{ data.item }} </template>
                  </HbSelect>
                </v-col>
                <v-col cols="6" class="py-0 my-0">
                  <HbTextField
                    v-model="form.business_information.facility_phone.phone"
                    v-validate="'required|length:14'"
                    v-mask="'(###) ###-####'"
                    placeholder="Number"
                    data-vv-name="storage_facility_phone_number"
                    data-vv-as="Number"
                    data-vv-scope="business_info"
                    :error="
                      errors.collect(
                        'business_info.storage_facility_phone_number'
                      ).length > 0
                    "
                    full
                  />
                </v-col>
                <v-col cols="3" class="py-0 my-0">
                  <HbTextField
                    v-model="form.business_information.facility_phone.extension"
                    placeholder="Extension"
                    data-vv-name="storage_facility_phone_extension"
                    data-vv-as="Extension"
                    data-vv-scope="business_info"
                    :error="
                      errors.collect(
                        'business_info.storage_facility_phone_extension'
                      ).length > 0
                    "
                  />
                </v-col>
              </v-row>
            </div>
          </hb-form>
          <hb-form
            v-if="!isRegisteredBusiness"
            label="Business Email"
            description="This email address must be unique and not associated with any other Tenant Payments account."
            required
            extraLarge
          >
            <div class="fields-wrapper">
              <HbTextField
                v-model="form.business_information.business_email"
                v-validate.disable="'required|email'"
                placeholder="Enter Email"
                data-vv-name="email"
                data-vv-as="Email"
                data-vv-scope="business_info"
                :error="errors.collect('business_info.email').length > 0"
                @input="lowercaseInput"

              />
            </div>
          </hb-form>
          <hb-form label="Transaction Data" 
          description="This must be the % of estimated usage"
          required extraLarge>
            <div class="fields-wrapper">
              <v-row>
                <v-col class="py-0 my-0">
                  <HbTextField
                    v-model.number="form.business_information.card_present"
                    v-validate.disable="{ required: true}"
                    @keypress="onlyNumbers"
                    v-mask=" '###'"
                    :class="{ 'is-invalid': hasSumError('card_present') }"
                    placeholder="Estimated percentage of transactions via Card Present (Chip, Touch, Swipe, etc)."
                    data-vv-name="card_present"
                    data-vv-as="Card Present Estimation"
                    data-vv-scope="business_info"
                      :error="hasError('card_present')"
                    full
                  />
                </v-col>
              </v-row>
              <v-row>
              <v-col class="py-0 my-0">
                <HbTextField
                  v-model.number="form.business_information.card_not_present"
                  v-validate.disable="{ required: true}"
                  @keypress="onlyNumbers"
                  v-mask=" '###'"
                  :class="{ 'is-invalid': hasSumError('card_not_present') }"
                  placeholder="Estimated percentage of transactions through Card but keyed in."
                  data-vv-name="card_not_present"
                  data-vv-as="Card Not Present Estimation"
                  data-vv-scope="business_info"
                   :error="hasError('card_not_present')"
                  full
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 my-0">
                <HbTextField
                  v-model.number="form.business_information.not_present_ecommerce"
                  v-validate.disable="{ required: true,  
                    sumTo100: { card_present: form.business_information.card_present, card_not_present: form.business_information.card_not_present, not_present_ecommerce: form.business_information.not_present_ecommerce } }"
                  placeholder="Estimated percentage of card transactions by customers via website"
                  data-vv-name="not_present_ecommerce"
                  data-vv-as="Card Not Present Ecommerce Estimation"
                  data-vv-scope="business_info"
                  :error="hasError('not_present_ecommerce')"
                  full
                />
              </v-col>
            </v-row>
              </div>
              </hb-form>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ADDRESS from "@/constants/address.js";
import PHONE from "@/constants/phone.js";
export default {
  name: "BusinessInfo",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      panel: [0],
      countryCodes: ["USA"],
      time_zone: ["UTC", "PT", "MST", "MT", "CT", "ET", "HST", "AT", "AST", "AKST"],
      states: ADDRESS.STATES,
      codes: PHONE.COUNTRY_CODES,
    };
  },
  computed: {
    lowercaseInput() {
      return this.form.business_information.business_email = this.form.business_information.business_email ? this.form.business_information.business_email.toLowerCase(): '';
      },
    isRegisteredBusiness() {
      return this.form.general.entity_type === "public_registered_business" || this.form.general.entity_type === "private_registered_business";
    }, 
    totalExceedsLimit() {
      const total = this.form.business_information.card_present + this.form.business_information.card_not_present + this.form.business_information.not_present_ecommerce;
      if((this.form.business_information.card_present || this.form.business_information.card_not_present || this.form.business_information.not_present_ecommerce)) {
        return total > 100 || total < 100;
      } else if((this.form.business_information.card_present === 0 && this.form.business_information.card_not_present === 0 && this.form.business_information.not_present_ecommerce === 0)) {
        return total < 100;
      }
    },
  },
    methods: {
      onlyNumbers(event) {
        const allowedChars = /[0-9]/;
        if (!allowedChars.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete') {
          event.preventDefault();
        }
      },
      hasSumError(field) {
        let total = this.form.business_information.card_present + this.form.business_information.card_not_present +  this.form.business_information.not_present_ecommerce;
        console.log('sum--> ', this.form.business_information.card_present + this.form.business_information.card_not_present +  this.form.business_information.not_present_ecommerce);
        let isTotalInvalid = total!==100;
        if(field) {
          return isTotalInvalid && (field === 'card_present' || field === 'card_not_present');
        }
        return isTotalInvalid;
      },
      hasError(field) {
      const fieldErrors = this.errors.collect(`business_info.${field}`).length > 0;
      return fieldErrors || this.totalExceedsLimit;
    },
    },
};
</script>

<style lang="scss" scoped>
.business-info-wrapper {
  .top-header {
    font-size: 14px;
    color: #101318;
    line-height: 24px;
    margin: 0px;
  }
  .fields-wrapper {
    width: 538px;
    @media only screen and (max-width: 960px) {
      width: 100%;
    }
  }
  .is-invalid {
    border-color: red;
  }
}
</style>
