<template>
    <div>
<!--      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>-->
<!--      <v-row v-if="properties.length > 1">-->
<!--        <v-col class="mr-auto mt-0 pt-0" style="flex: 0 1 300px;">-->
<!--          <v-autocomplete-->
<!--            v-if="filters"-->
<!--            :key="report_key"-->
<!--            dense-->
<!--            hide-details-->
<!--            rounded-->
<!--            :items="properties"-->
<!--            item-text="name"-->
<!--            item-value="id"-->
<!--            label="Select A Facility"-->
<!--            v-model="property_id"-->
<!--            background-color="white"-->
<!--            singleLine-->
<!--            outlined-->
<!--            prepend-inner-icon="mdi-magnify"-->
<!--            placeholder="Select A Facility"-->
<!--            @change="updateChart"-->
<!--          ></v-autocomplete>-->
<!--        </v-col>-->
<!--      </v-row>-->

      <div class="report-unit-list" :style="{ height: reportHeight + 'px'}" v-resize="setHeight">
        <hb-report
              report_type="move_in"
              :key="report_key"
              :report_filters="filters"
              row_click
              show_filters
              :disable_header_filters="true"
              :column-conf="columnConf"
              :conditions="condition"
              @rowClicked="setUnit"
          ></hb-report>
      </div>
    </div>
</template>


<script type="text/babel">
    import api from '../../../assets/api.js';
    import HbReport from '../../assets/HummingbirdReportViewer.vue';
    import { EventBus } from '../../../EventBus.js'
    import { mapGetters } from 'vuex';
    import MultiLineRenderer from '../../../components/BI/MultiLineRenderer.vue'
    import UnitIconField from '../../revenue_management/utils/UnitIconField.vue';
    export default {
        name: 'UnitSelector',
        components: {
            HbReport,
            MultiLineRenderer
        },
        data: () => ({
            options: {},
            report_key: 0,
            property_id: '',
            panel: null,
            reportHeight: 0,
            table_data: [],
            filters: {
                search: {
                    search: '',
                    property_id: [],
                    unit_status: ['Available'],
                    unit_size: [],
                    unit_amenities: [],
                    unit_floor: [],
                    unit_price: [],
                    unit_type: [],
                },
                columns: [
                    {
                        "label": "Space #",
                        "key": "unit_number",
                        "sortable": true,
                        "width": "90",
                        "column_type": "string",
                        "agg_method": "Count",
                        "format": "",
                        "fixed": false,
                        "group": "unit",
                        "hide": true
                    },
                    {
                        "label": "Space #",
                        "key": "unit_size",
                        "sortable": true,
                        "width": "90",
                        "column_type": "string",
                        "agg_method": "Count",
                        "format": "",
                        "fixed": false,
                        "group": "unit"
                    },
                    {
                        "label": "Price",
                        "key": "unit_price",
                        "sortable": true,
                        "width": "84",
                        "column_type": "money",
                        "agg_method": "Count",
                        "format": "",
                        "fixed": false,
                        "group": "unit",
                        "input": "comparison"
                    },
                    {
                        "label": "Amenities",
                        "key": "unit_amenities",
                        "sortable": true,
                        "width": 95,
                        "column_type": "concat",
                        "agg_method": "Count",
                        "format": "",
                        "fixed": false,
                        "input": "multi-select",
                        "group": "unit",
                    },
                    {
                        "label": "Eligible In-Store Promotions",
                        "key": "unit_eligible_in_store_promotion",
                        "sortable": true,
                        "width": "150",
                        "column_type": "string",
                        "format": "",
                        "fixed": false,
                        "group": "unit",
                    },
                    {
                        "label": "Status",
                        "key": "unit_status",
                        "sortable": true,
                        "width": "115",
                        "column_type": "status",
                        "agg_method": "Count",
                        "format": "",
                        "fixed": false,
                        "group": "unit"
                    },
                ],
                "sort": {
                    "field": "unit_number",
                    "sort": "ASC"
                },
                "pivot_mode": {
                    "enabled": false,
                    "column": {},
                    "metric": {
                        "field": {},
                        "method": ""
                    },
                    "row": {}
                },
                "groups": [],
                "limit": 50,
                "page": 1,
                "offset": 0
            }
        }),
        async created(){
            this.property_id = this.property.id;
            if(this.property.id){
                this.filters.search.property_id = [this.property_id];
                this.report_key++;
            }

            if(this.search && this.search.type && this.search.type.length) {
                this.filters.search.unit_type_id = JSON.parse(JSON.stringify(this.search.type));
            }
            this.setHeight();
            this.searchUnits();

        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                property: 'onBoardingStore/property',
            }),
            columnConf() {
                return {
                    unit_price: {
                    },
                    unit_size: {
                        cellRenderer: "MultiLineRenderer",
                        cellRendererParams: {
                            fields: [
                            {
                                column: "unit_number",
                                tooltip: true,
                                component: {
                                component: UnitIconField,
                                props: {
                                    position: "left",
                                    applyStroke: false,
                                    small: true,
                                    width: 16,
                                    height: 16,
                                    color: "black",
                                    marginBottom: "0px",
                                    verticalAlign: "middle",
                                },
                                },
                            },
                            {
                                column: "unit_size",
                                color: "#8E8E8F",
                                tooltip: true,
                            },
                            ],
                        },
                    },
                }
            }
        },
        filters:{
           promotions_display(data){
                let promotions = data.split(',');
                if(!promotions.length) return ''
                return promotions[0] + ' + ' + (promotions.length - 1) + ' more';
           },
        },
        props: ['search', 'loading', 'condition', 'allSpaces'],
        methods:{
            searchUnits(){
               // EventBus.$emit('search_filter',{
               //     search:this.filters.search,
               //     template: 'move_in'
               //  })
            },
            async setUnit(data){
                this.$emit('setUnit', data.unit_id);
            },
            setHeight(){
                let header = 72;
                let stepper = 100;
                let tabs = 48;
                let footer = 91;
                let heading = 122;
                let padding = 20;
                this.reportHeight = window.innerHeight - header - stepper - tabs -  heading - footer - padding;
            }
        },
        watch:{
          "property.id"(){
              this.property_id = this.property.id;
              this.filters.search.property_id = [this.property.id];
              if(this.property.id){
                this.searchUnits();
                this.report_key++;

              }
          },
          search: {
              async handler(value) {
                  this.filters.search.search = value.search || ''
                  this.filters.search.unit_floor = value.access;
                  this.filters.search.unit_size = value.size;
                  this.filters.search.unit_type_id = value.type;
                  this.filters.search.unit_status = value.status

                  if(value.price && value.price.length){
                     this.filters.search.unit_price = []
                        value.price.forEach((price)=>{
                            switch(price){
                            case '< $50':
                            this.filters.search.unit_price.push( { max: "", operator: "<", value: "50"})
                            break;
                            case '$50 - $100':
                            this.filters.search.unit_price.push( { max: "100", operator: "range", value: "50"})
                            break;

                            case '$100 - $150':
                                this.filters.search.unit_price.push( { max: "150", operator: "range", value: "100"})
                                break;

                            case '$150 - $300':
                                this.filters.search.unit_price.push( { max: "300", operator: "range", value: "150"})
                                break;

                            case '> $300':
                                this.filters.search.unit_price.push( { max: "", operator: ">", value: "300"})
                                break;

                        }
                    })
                    
                  } else {
                      this.filters.search.unit_price = value.price
                  }

                  this.filters.search.unit_amenities = value.amenities;

                  this.searchUnits();
                  this.report_key++;
              },
              deep : true
          },
        }
    }

</script>

<style scoped>
  .unit-selector-dt{
    border: 1px solid rgba(11, 18, 29, .1)
  }

</style>


