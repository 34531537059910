<template>

    <div>
      <div v-if="loading">
        <v-row justify="center" align="center">
          <v-col class="text-center ma-12">
            <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
          </v-col>
        </v-row>
      </div>
      <div v-else>

        <div class="services-section">
          <payment-process
            :contactID="contact_id" 
            :propertyID="property.id" 
            paymentSource="MOVE_IN"
            @initialLoading="handelLoading">
          </payment-process>
        </div>
        <div class="new-tenant-walkthrough-footer" >
          <hb-bottom-action-bar
            @close="$emit('close')"
            :top-border="false"
          >
              <template v-slot:left-actions>
                <!-- <help-juice /> -->
            </template>
            <template v-slot:right-actions v-if="!contentLoader">
              <hb-btn
               v-if="hasPermission('retire_lead')"
                color="secondary"
                :disabled="isLoading($options.name) || processPaymentInProgress || skipPaymentInProgress || getDisablePaymentButton"
                @click="$emit('retire_lead')"
              >
                Retire Lead
              </hb-btn>
              <hb-btn color="secondary" :disabled="isLoading($options.name) || processPaymentInProgress || skipPaymentInProgress || processPaymentStatus"
                      :loading="skipPaymentInProgress" v-if="hasPermission('move_in_without_pay')"
                      @click="skipPayment">Skip Payment</hb-btn>
              <hb-tooltip v-else>
                <template v-slot:item >
                  <hb-btn color="secondary" disabled>Skip Payment</hb-btn>
                </template>
                <template v-slot:body> You do not have the permission to skip payment </template>
              </hb-tooltip>
              <hb-btn
                :disabled="processPaymentInProgress || skipPaymentInProgress || processPaymentStatus"
                @click="generateLink"
              >
                Send Payment Link
              </hb-btn>

              <hb-btn v-if="inCall && ivrPaymentType === 'card' &&  IvrPaymentEnable && ivrPaymentMethod == 'new' " color="primary" @click="completeSession()" :disabled="!enableIvrProcessPayment" :loading="ivrPaymentButtonLoading">Process Payment  {{ivrTimeout}}</hb-btn>
              <hb-btn
              v-else
                :disabled="isLoading($options.name) || processPaymentInProgress || skipPaymentInProgress || getDisablePaymentButton || checkIfTotalDueIsZero || processPaymentStatus"
                @click="checkPaymentsErrors"
                :loading="processPaymentInProgress"
              >
                Process Payment
              </hb-btn>

            </template>
          </hb-bottom-action-bar>
          <hb-modal
            size="medium"
            title="Confirm"
            v-model="showPayLinkModal"
            :footerCancelOption="false"
            confirmation
          >
            <template v-slot:content>
                <div class="py-4 px-6 hb-font-header-3">
                    How do you want to send the payment link?
                </div>
                  <hb-checkbox
                    v-model="emailCheck"
                    label="Email"
                    class="mb-2 px-6"
                  >
                  </hb-checkbox>
                  <div class="mb-1 px-6 hb-font-header-3">{{ contact.email }}<v-divider v-if="contact.Phones[0].phone && contact.Phones[0].sms" length="64"></v-divider > </div>
                  <hb-checkbox 
                    v-if="contact.Phones[0].phone && contact.Phones[0].sms"
                    v-model="textCheck"
                    label="Text"
                    class="mt-4 mb-1 px-6"
                  >
                  </hb-checkbox>
                  <div v-if="contact.Phones[0].phone && contact.Phones[0].sms" class="mb-4 px-6 hb-font-header-3">{{ contact.Phones[0].phone | formatPhone}}<v-divider length="64"></v-divider > </div>
            </template>

            <template v-slot:right-actions>
                <hb-link @click="closeDialogHandler">Cancel</hb-link>
                <hb-btn @click="sendPayLinkMessage()" :disabled="sendPaymentLinkInProgress || (!emailCheck && !textCheck)" :loading="sendPaymentLinkInProgress">Send</hb-btn>
            </template>
            
          </hb-modal>
          <hb-modal
            size="medium"
            title="Skip Payment"
            v-model="showSkipPaymentMessage"
            :footerCancelOption="false"
            confirmation
          >
            <template v-slot:content>
              <div class="py-4 px-6">
                {{ skipPaymentMessage }}
              </div>
            </template>
            <template v-slot:right-actions>
                <hb-link :disabled="isLoading($options.name) || processPaymentInProgress || skipPaymentInProgress || processPaymentStatus" @click="() => showSkipPaymentMessage = false">Cancel</hb-link>
                <hb-btn :disabled="isLoading($options.name) || processPaymentInProgress || skipPaymentInProgress || processPaymentStatus" :loading="skipPaymentInProgress" @click="proceedWithoutPayment()" >Skip Payment</hb-btn>
            </template>
          </hb-modal>
        </div>
      </div>
    </div>
    
</template>


<script type="text/babel">
    import PaymentProcess from '../PaymentProcess/Index.vue';
    import api from '../../../assets/api.js';
    import Status from '../../includes/Messages.vue';
    import {charges} from '../../../mixins/leaseCharges.js'
    import CollectPayment from '../CollectPayment.vue';
    import { EventBus } from '../../../EventBus.js';
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import axios from 'axios';
    import https from 'https';

    export default {
        name: "Payments",
        data() {
            return {
                property_id: '',
                contact_id: '',
                collect_payments: 0,
                panel: 0,
                payment_methods: [],
                auto_charge:false,
                prepay: null,
                show_charge: null,
                minNoOfAdditionalMonths: 0,
                skipPaymentInProgress: false,
                processPaymentInProgress: false,
                showPayLinkModal: false,
                showSkipPaymentMessage: false,
                Message: '',
                Subject:'',
                chatError: '',
                emailCheck: false,
                textCheck: false,
                textMessage: '',
                sendPaymentLinkInProgress:false,
                preventDialogClose:false,
                loadingSendPayLinkButton:false,
                disableSendPayLinkButton:false,
                contentLoader: false,
            }
        },
        components:{
            Status,
            CollectPayment,
            PaymentProcess
        },
        props: ['loading'],
        async created (){
            this.updateIvrPaymentEnable(true)
            this.contact_id = this.tenants[0].Contact.id;
            this.$emit("update:loading", false);
            EventBus.$on('paymentReader', this.enablePayment);
            EventBus.$on('enablePayment', (data) => {
              this.enablePayment(data)
    });
          
        },
        destroyed(){
            this.updateIvrPaymentEnable(false)
            EventBus.$off('paymentReader', this.enablePayment);
            this.closeMessageNotification();
            this.updateIvrPaymentEnable(false)
        },
        filters:{
          
        },
        computed: {
          inCall() {
              return this.notification.status === 'active';
            },
          ivrTimeout(){
              return this.ivrTimer
          },
          paymentRequired(){
            return this.lease.promotions.some(a1 => this.activePromotions.find(a2 => a2.id === a1.promotion_id && a2.required_months > 0)) || false;
          },
          autopayRequired(){
            return this.lease.promotions.some(a1 => this.activePromotions.find(a2 => a2.id === a1.promotion_id && a2.conditions?.includes("require_autopay"))) || false;
          },
          skipPaymentMessage(){
            return `Some promotion(s) require ${this.paymentRequired ? `payment ${this.autopayRequired ? ' and auto pay enrollment' : ''}` : `${this.autopayRequired? 'auto pay enrollment' : ''}`}. If you skip payment, they will be removed.`
          },
            ...mapGetters({
                ivrTimer:'charmCallStore/ivrTimer',
                ivrPaymentButtonLoading:'charmCallStore/ivrPaymentButtonLoading',
                paymentConnectorResponse:'charmCallStore/paymentConnectorResponse',
                enableIvrProcessPayment:'charmCallStore/enableIvrProcessPayment',
                ivrPaymentType:'charmCallStore/ivrPaymentType', 
                IvrPaymentEnable:'charmCallStore/IvrPaymentEnable',
                ivrPaymentMethod:'charmCallStore/ivrPaymentMethod',
                paymentData: 'paymentsStore/getPaymentInformation',
                notification:'charmCallStore/notification',
                payment: 'paymentsStore/getPayment', 
                property: 'onBoardingStore/property',
                properties: 'propertiesStore/filtered',
                categories: 'unit_category_id',
                lease: 'onBoardingStore/lease',
                unit: 'onBoardingStore/unit',
                insurance: 'onBoardingStore/insurance',
                promotions: 'onBoardingStore/promotions',
                invoices: 'onBoardingStore/invoices',
                contact: 'onBoardingStore/contact',
                tenants: 'onBoardingStore/tenants',
                tenantAddress: 'onBoardingStore/contact',
               // billed_months: 'onBoardingStore/billed_months',
                getErrors: 'paymentsStore/getErrors',
                hasPermission: "authenticationStore/rolePermission",
                getDisablePaymentButton: 'paymentsStore/getDisablePaymentButton',
                processPaymentStatus: 'onBoardingStore/processPaymentStatus',
                activePromotions: 'revManStore/getActivePromotions'
            }),
            promotion(){
                if(!this.lease.promotion_id)  return '';
                let promo = this.promotions.find( p => p.id === this.lease.promotion_id );
                if(!promo) return '';
                return promo;
            },
            checkIfTotalDueIsZero() {
              if(this.invoices.length > 0) {
                    let inv = this.invoices;
                    const sum = inv.reduce((total, invoice) => { return total + invoice.total_due; }, 0);
                    if(sum === 0) {
                        return true;
                    } else {
                        return false;
                    }
              } else {
                return false;
              }
            },
                    },
        methods: {
          async completeSession(){
            EventBus.$emit('complete-ivr-payment-session');
          },      
          ...mapActions({ 
            getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject',
            generateInvoice: 'onBoardingStore/generateInvoice',
            payInvoice: 'onBoardingStore/payInvoice',
            payInvoicePAXLAN: 'onBoardingStore/payInvoicePAXLAN', 
            getEstimate: 'onBoardingStore/getEstimate'
          }),
          ...mapMutations({
            updateIvrPaymentEnable:'charmCallStore/updateIvrPaymentEnable',
            setCheckErrors: 'paymentsStore/setCheckErrors',
            checkOnlyCredit:'paymentsStore/checkOnlyCredit',
            setBilledMonths: 'onBoardingStore/setBilledMonths',
            setLeaseProperty: 'onBoardingStore/setLeaseProperty'
          }),
          async enablePayment(response) {
            if(response && response.error) {
              this.processPaymentInProgress = false;
              this.showMessageNotification({ list: [response.error] });
            } else if(response && response.data && response.data.msg) {
              this.showMessageNotification({ type: 'success', description: response.data.msg.text });
              await this.getEstimate({generateInvoices: true});
              EventBus.$emit('payment_saved');
              this.$emit('saved');
            }
          },
            async skipPayment(){
              if(this.paymentRequired || this.autopayRequired){
                this.showSkipPaymentMessage = true;
              } else {
                await this.proceedWithoutPayment();
              }
            },
            async proceedWithoutPayment(){
              let promotions_to_remove = [];
              const promotions_prepay = this.lease.promotions.filter(a1 => 
                this.activePromotions.find(a2 => a2.id === a1.promotion_id && a2.required_months > 0)
              ) || [];
              const promotions_autopay = this.lease.promotions.filter(a1 => 
                this.activePromotions.find(a2 => a2.id === a1.promotion_id && a2.conditions?.includes("require_autopay"))
              ) || [];
              promotions_to_remove = [...promotions_prepay, ...promotions_autopay]
              if (this.hasPermission('move_in_without_pay')){
                this.skipPaymentInProgress = true;
                this.disableSendPayLinkButton = true;
                if(this.invoices.length === 0  || !this.invoices[0].id){
                  this.setBilledMonths(0);
                  this.setLeaseProperty({ property: 'billed_months' , propertyValue: 0 });
                  await this.generateInvoice({ promotions_to_remove });
                }
                this.$emit('saved');
                this.skipPaymentInProgress = false;
                this.disableSendPayLinkButton = false;
              }else{
                this.showMessageNotification({ type: 'error', description: 'You do not have the permission to skip payment' });
              }
            },
            async fetchPropertyConnectionsbyID(device_id,property_id) {
              let r = await api.get(this.$app, api.PROPERTIES + property_id + "/connections/address?device_id=" + device_id);
              this.IPaddress = r.IPaddress;
              this.lan = r.lan;
            },
            checkPaymentsErrors() {
              this.disableSendPayLinkButton = true;
              this.checkOnlyCredit();
              this.disableSendPayLinkButton = false;
            },
            // Go ahead and charge now...
            async processPayment() {
              EventBus.$emit('disable_reader_dropdown');
              this.processPaymentInProgress = true;
              if(this.invoices.length === 0 || !this.invoices[0].id) {
                try{
                  await this.generateInvoice();
                  EventBus.$emit('updatePaymentApplication');
                } catch(err) {
                  let response = {
                    error: err
                  };
                  this.enablePayment(response);
                  EventBus.$emit('enable_reader_dropdown');
                  return;
                }
                
              }else {
                EventBus.$emit('updatePaymentApplication');
                EventBus.$emit('enable_reader_dropdown');
              }
              
              const paymentInfo = await this.getTransformedPaymentObject({
                id: this.$options.name,
                formErrors: this.formErrors
              });

              if(paymentInfo.errors && paymentInfo.errors.length) {
                this.showMessageNotification({ list: paymentInfo.errors });
                this.processPaymentInProgress = false;
                EventBus.$emit('enable_reader_dropdown');
                return;
              }

              if(paymentInfo && paymentInfo.payment) {
                const { payment, paymentMethod, contact_id, property_id, Invoices, use_credits,auto_pay_leases, partial_payment } = paymentInfo;
                let response = {};

                let paymentInformation = {
                    payment: payment,
                    paymentMethod: paymentMethod,
                    contact_id: contact_id,
                    property_id: property_id,
                    use_credits,
                    auto_pay_leases,
                    Invoices: Invoices.map(i => {
                      return {
                        id: i.id,
                        amount: i.amount,
                        credits_amount_used: i.credits_amount_used,
                      }
                    }),
                    paymentSource: 'MOVE_IN',
                    partial_payment
                }
                let payloadResponse;
                let lanResponseData;
                let payment_id;
                let responseData;
                //Fetch Device IP and Port for Lan Connection
                if(paymentInfo?.paymentMethod?.device_id)
                await this.fetchPropertyConnectionsbyID(paymentInfo.paymentMethod.device_id,paymentInfo.property_id);
                try {
                  if(paymentInfo.paymentMethod && paymentInfo.paymentMethod.device_id && this.lan == 1){
                  // Create an instance of the HTTPS agent with certificate verification enabled.
                  const certData = `-----BEGIN CERTIFICATE-----
MIIDzTCCArWgAwIBAgIBAjANBgkqhkiG9w0BAQsFADBtMQswCQYDVQQGEwJVUzEL
MAkGA1UECAwCRkwxDDAKBgNVBAcMA0pBWDEMMAoGA1UECgwDUEFYMRQwEgYDVQQL
DAtJTlRFR1JBVElPTjEfMB0GA1UEAwwWUEFYVVMgVEVSTUlOQUwgUk9PVCBDQTAe
Fw0xODA4MDYwNzMyMTJaFw0yODA4MDMwNzMyMTJaMGoxCzAJBgNVBAYTAlVTMQsw
CQYDVQQIDAJGTDEMMAoGA1UECgwDUEFYMRQwEgYDVQQLDAtJTlRFR1JBVElPTjEq
MCgGA1UEAwwhUEFYVVMgVEVSTUlOQUwgU0VSVkVSIENFUlRJRklDQVRFMIIBIjAN
BgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwbJg+g0N/M+ohU4sTpNHY25j0tH3
6f6kk5M564g6/kBv8WWL/4QnpLHfGVFdBC+P30JW9QjOGJj5OY5suQm0xqs6/rD1
qb1cWbBaeTvOMmx+79uIuQweRAfbAOdc3RdKSz3nR/IfZ1059FG23/8IF9/pRXuM
oeFl4MzWwoxkqJ24Q216aD+fJvGNjoajuBbnyM+5WQDRqBE+GI04ElWDDLLft/Kx
cQwL/gjxFeGSmDg88yHPcimcIulnGnmc6M3SV5RbGVHbCtOwzwjQdUndom9lDZ82
VkBPUaYgiMnFzbbYF9FHFP2xD93qvWrBpc5GGQleDsH8LLHsXbZdPbvdnwIDAQAB
o3sweTAJBgNVHRMEAjAAMAsGA1UdDwQEAwIFoDAdBgNVHQ4EFgQUuWH25D/quAew
5neUSAjaqxe6LCUwHwYDVR0jBBgwFoAUKyQA82I7zBIeEmhqHya07aPrLHYwHwYD
VR0RBBgwFoIIKi5wYXgudXOHBH8AAAGHBMCoYnIwDQYJKoZIhvcNAQELBQADggEB
ADBQHlhlEHzmyVLiAoInaxbP7s8hN29VcWXUQXo5VrbfWCB26zfY0mFgZezMa7VB
dzzflJ/XeqDs+HgQ5KQ5cXL4PO+jpFvPLPj7wfy02YdyjuKu/EDMkrxLXwJm8TJY
qeVd/W5phCxqXc/+5jvvrGGdOTd+tgpz/DndKl4mdboqWeqCJxVWbxZez2FDrUzP
cPHhEt+gfJFr2ARYhPwf7jD5/97YV2ULeYxoOkr+qF5GdTdSxn944FM8l8ZGrRpe
rkhYcw+B0V6MgdoFGhkBDXbos0KiUUbLVgCyf+ewUQuc1+2q9++s+amJ0svydr1V
0gVZN3O9nql8n0IcpWtsyvQ=
-----END CERTIFICATE-----`;
                  const httpsAgent = new https.Agent({
						      ca: [certData],
						    });
                // Configure Axios to use the custom HTTPS agent.
						    const instance = axios.create({
						      httpsAgent
						    });
                const endpoint = 'https://' + this.IPaddress + '?';
                //Get PAX Request Payload from HB Backend server
                payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",paymentInformation);
                payment_id = payloadResponse.payment.id;
                paymentInformation.payment.id = payment_id;
			          // Make the GET request using the custom Axios instance.
			          lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
                paymentInformation.payment.PAXResponse = lanResponseData;
                responseData = await this.payInvoicePAXLAN(paymentInformation);
                } else {
                  responseData = await this.payInvoice(paymentInformation);
                  if(responseData.payment.id !== null && responseData.payment.id !== 'undefined'){
                    let data = {
                        payment_id: responseData.payment.id
                      }

                    await api.post(this, api.LEASES + this.lease.id + '/apply_unallocated_payment', data);
                  }
                }  
                    response = {
                      data: {
                        msg: {
                          id: this.$options.name,
                          text: responseData.msg,
                        },
                        payment: responseData.payment
                      }
                    };
                } catch(err){
                  response = {
                    error: err,
                    contact_id: paymentInfo.contact_id
                  };
                  if(paymentInfo.paymentMethod && paymentInfo.paymentMethod.device_id && this.lan == 1)
                  {
                    responseData = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
                  }  
                } finally {
                  this.enablePayment(response);
                  EventBus.$emit('enable_reader_dropdown');
                }
              }
            },
            async generateLink(){
              // await this.payNowLinkEmail();
              // await this.payNowLinkText();
              this.showPayLinkModal = true;
            },
            async payNowLinkEmail(){
              this.invoices[0].contact_id = this.contact.id
              let data = {
                invoices: this.invoices,
                communicationType: 'Email'
              }
              let r;
              try{
                r = await api.post(this, api.PAYMENTS + 'payment-link', data);
              }catch(error){
                console.log('error',error);
              }
              let buttonHtml = `</br>
                                  <a target="_blank" rel="noopener noreferrer nofollow" href="${r.link}">Pay Now</strong></a>`;
              let pay_message = `<p>Dear ${this.contact.first} ${this.contact.last},</p>
                <p>We hope this message finds you well. You have a payment associated with your storage rental at ${this.property.name}. To expedite the process, we've generated a secure payment link for your convenience.</p>
                <h3>Payment Instructions:</h3>
                <ol>
                    <li>Click on the following secure payment link: <b>${buttonHtml}</b></li>
                    <li>Enter your credit card information</li>
                    <li>Follow the on-screen instructions to complete the payment securely.</li>
                </ol>
                <p><strong>Important Notes:</strong></p>
                <ul>
                    <li>This payment link will expire in 24 hours, so please complete the payment promptly.</li>
                    <li>If this payment is not currently due or if you have already processed it, kindly disregard this message.</li>
                </ul>
                <p>Should you encounter any difficulties or have questions, please feel free to reply to this email or contact our support team at ${this.property?.Emails[0]?.email}.</p>
                <p>Thank you for your prompt attention to this matter. We appreciate your cooperation.</p>
                <p>Best regards,<br>
                ${this.property.name}<br>
                ${this.property.Address.address} ${this.property?.Address?.address2}<br> ${this.property?.Address?.city},  ${this.property?.Address?.state} -  ${this.property?.Address?.zip}<br></p> ${this.$options.filters.formatPhone(this.property?.Phones[0]?.phone)}<br> ${this.property?.Emails[0]?.email}<br>`
              this.Subject = `Action Required - Secure Payment Link Inside`
              this.Message = pay_message;
            },
            async payNowLinkText(){
              this.invoices[0].contact_id = this.contact.id
              let data = {
                invoices: this.invoices,
                communicationType: 'Text'
              }
              let r;
              try{
                r = await api.post(this, api.PAYMENTS + 'payment-link', data);
              }catch(error){
                console.log('error',error);
              }
              let pay_message = `Action Required - Secure Payment Link for ${this.invoices[0]?.Lease?.Unit?.number}. \n Link: ${r.link.shortUrl}`
              this.textMessage = pay_message;
            },
            async sendPayLinkMessage(){
              this.preventDialogClose = true
              this.sendPaymentLinkInProgress = true;
              await this.skipPayment()
              await this.payNowLinkEmail();
              await this.payNowLinkText();
              if(this.emailCheck === true){
                var data = {
                  message: this.Message,
                  subject: this.Subject,
                  space: 'Tenant',
                  send_email: true,
                  sms: [],
                  upload_id: null,
                  primary_contact_id:this.contact.id,
                  property_id:this.property.id
                }
                try {
                  await this.handleSendEmail(data)
                  this.showMessageNotification({
                    type: 'success', 
                    description: 'The payment link sent via email is successfully'
                  });
              this.sendPaymentLinkInProgress = false;
              
            } catch (error) {
              this.preventDialogClose = false
              this.sendPaymentLinkInProgress = false;
              this.showMessageNotification({
                    type: 'error',
                    description: 'The Email attempted either does not exist, does not allow sending email, or is blocked by a networking issue.'
                  });
                }
              }
              if(this.textCheck === true){
                var data = {
                  message: this.textMessage,
                  subject: ' ',
                  space: 'Tenant',
                  send_email: false,
                  sms: [this.contact?.Phones[0]?.id],
                  primary_contact_id: this.contact?.id,
                  property_id: this.property?.id
                }
                try {
                  await this.handleSendEmail(data)
                  this.showMessageNotification({
                    type: 'success', 
                    description: 'The payment link sent via text is successfully'
                  });
              this.sendPaymentLinkInProgress = false;
              
            } catch (error) {
              this.preventDialogClose = false
              this.showMessageNotification({
                type: 'error',
                description: 'The Text attempted either does not exist, does not allow sending text, or is blocked by a networking issue.'
              });
            }
          }
          
              this.sendPaymentLinkInProgress = false;
              this.showPayLinkModal = false;
            },
            async handleSendEmail(data){
              return api.post(this, api.CONTACTS + this.contact.id + '/send-message', data)
            },
            closeDialogHandler() {
              if (this.preventDialogClose) {

              } else {
                this.showPayLinkModal = false;
              }
            },
            handelLoading(flag){
              this.contentLoader = flag
            }
        },
        mixins:[charges, notificationMixin],

        watch: {
          paymentConnectorResponse(payload){
            if(!payload) return
            if(payload.status == 200){
                  let    response = {
                    data: {
                      msg: {
                        id: this.$options.name,
                        text: payload.msg,
                      },
                      payment: payload.payment
                    }
                  };

                  EventBus.$emit('lease_edited');
                  EventBus.$emit('refresh_lease_tenants');
                  EventBus.$emit('payment_methods_updated');
                  this.enablePayment(response);
                }else{
                 let response = {
                    error: payload.msg,
                  };
                  this.enablePayment(response);
                }
          },
          getErrors(value) {
            EventBus.$emit('success-error-message-close');
            if(value === false) {
              this.processPayment();
              this.setCheckErrors({ value: null });
            }
          }
                }
    }
</script>

<style scoped>

    .new-tenant-walkthrough-footer{
        /* padding: 3px 0 3px 0; */
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 16px 24px 100px 24px;
        background: #F9FAFB;
    }

    .unit-confirm-icon .v-list-item__icon {
        text-align: center;
        background: white;
        padding: 25px;

    }

    .v-expansion-panel-content .form-label{
        background: #F4F6F8;
        padding: 20px;
        font-weight: bold;
    }

    .payments-container{
        border: 1px solid #C4CDD5;
        background: white;
        border-radius: 4px;
        padding: 30px 50px;
    }
    .v-expansion-panel-content__wrap,
    .v-expansion-panel-content.no-padding .v-expansion-panel-content__wrap{
        padding: 0;
    }
    .v-expansion-panel-content__wrap {
        padding: 0px;
    }

    .invoice_display{
        padding: 20px;
        font-size: 14px;
    }
    .breakdown{
        display:inline-block;
        flex:0 0 100%;
        padding: 10px;
        width: 100%;
        background:#f2f9fd;
        border: 1px solid #dce8ef;
    }
    .new-tenant-form-data{
      border: 1px solid #DFE3E8;
    }

    .v-expansion-panel-content .form-label{
      background: #F4F6F8;
      padding: 20px;
      font-weight: bold;
    }

    .invoice_display{
      padding:  10px 0px;
      font-size: 14px;
    }

    .invoice-line-row{
      margin-bottom: 0;
      padding: 0px 20px;
    }
    .invoice-line-row .col {
      padding: 0;
    }

    .invoice-total-row >.col{
      padding: 10px;
    }

    .invoice-row .caption{
      color: #637381
    }

    .invoice-row > .col{
      padding-bottom: 0;
    }

    .invoice-line-row.total{
      border-top: 1px solid #C4CDD5;
      margin-top: 20px;
    }
    .invoice-line-row.total > .col{
      padding-top: 15px;
    }


    .payment-data-header .v-input--checkbox,
    .form-label .v-input--checkbox{
      margin-top: 0;
      padding-top: 0;
    }

    .payment-data-header{
      border-bottom: 1px solid #DFE3E8;
      padding: 15px 20px;
      margin-bottom: 0;
    }

    .container.payment-data-container {
      padding: 0 12px;
    }

    .card-options{
      border-right: 1px solid #C4CDD5;
      padding: 10px;
      font-size: 14px;
    }

    .payment-method-holder{
      padding: 10px;
    }
</style>
