<template>
    <div class="lead-intake-container">
        <hb-blade-header title-icon="mdi-currency-usd" title="Record a Payments" backButtonOff @close="$emit('close')">
        </hb-blade-header>
        <div class="lead-intake-top" no gutters>
            <!-- left side -->
            <div class="navigation-panel-container">
                <div class="pa-4">
                    <hb-mini-profile type="account" :title="contact.Business.name" 
                    :phone="contact.Phones[0].phone | formatPhone" :status="contact.status ? contact.status : undefined"
                     :can-text="true" :email="contact.email" :representative-name="contact.first + ' ' + contact.last"
                        :address="computedBusinessAddress" :id-verified="contact.email_verified
                            ? contact.email_verified
                            : undefined
                            " :account-space-count="calculateLength"
                        :account-space-type="contact.Leases && contact.Leases?.length === 1 ? contact?.Leases[0]?.Unit?.type : ''"
                        />
                    <v-divider class="my-5 pa-0 mt-3"></v-divider>


                    <v-expansion-panels  v-model="panel" multiple left-cols="7" right-cols="5">
                            <v-row no-gutters v-if="invoice.length" color="#101318" class="hb-font-header-2-medium mt-n1">
                                Invoice Information
                            </v-row>
                            
                        <hb-expansion-panel :left-cols="6" :right-cols="5" v-for="(line, i) in invoice" :key="i" class="my-5" disable-title-icon>
                             <template v-slot:title class="d-flex align-center justify-center">
                                <span @click.stop >
                                    <hb-checkbox v-model="pay[i]" @change="addPayment(line,i)"  :label="line.invoice_number"></hb-checkbox>
                                </span ></template>
                            <template v-slot:actions>
                                <span class="pr-6 hb-font-header-3-medium">
                                    <span class="pr-4  hb-font-header-3 ">Total Due:</span>{{ line.balance  | formatMoney
                                    }}
                                </span>
                            </template>

                            <template v-slot:content>
                                <v-container fluid>
                                    <v-row>
                                        <v-col>
                                            <span class=" mt-4 pr-6 hb-font-header-3-medium ">Invoice Total:</span>
                                        </v-col>
                                        <v-col class="pr-6 hb-font-header-3-medium d-flex justify-end ">
                                            <span class="pr-4">{{ line.amount  | formatMoney }}</span>
                                        </v-col>

                                    </v-row>
                                    <v-row v-if="line.balance != line.amount">
                                        <v-col>
                                            <span class="mt-4 pr-6 hb-font-header-3 ">Previous Payments:</span>
                                        </v-col>
                                        <v-col class="pr-6 hb-font-header-3-medium d-flex justify-end ">
                                            <span class="pr-4">-{{ line.amount - line.balance | formatMoney }}</span>
                                        </v-col>

                                    </v-row>
                                </v-container>
                                <v-divider></v-divider>
                                <v-container fluid>
                                    <v-row class="pr-6 justify-end">
                                        <span class="pr-6 hb-font-header-3 ">Balance Due:</span> <span
                                            class="pr-4 hb-font-header-3-medium d-flex justify-end">{{ line.balance | formatMoney }}</span>
                                    </v-row>
                                </v-container>
                            </template>
                        </hb-expansion-panel>
                            <v-row no-gutters color="#101318" v-if="invoice.length" class="hb-font-header-2-medium mt-n1">
                                Payment Information
                            </v-row>
                        <hb-expansion-panel v-if="invoice.length" :left-cols="6
            " :right-cols="5" expand-icon-off>
                            <template v-slot:title class=" ma-0 pa-0 mt-6 d-flex ">
                                <span @click.stop>
                                    <hb-checkbox v-model="edit"  @change="setPayment(edit)" label="Reallocate or Choose a different amount"></hb-checkbox>
                                </span>
                               </template>
                            <template v-slot:actions >
                                <v-row  @click.stop class="align-center  hb-font-header-3-medium d-flex justify-end">
                                    <span>Total Payment : </span>
                                     <hb-text-field v-if="edit" condensed box medium v-model="total_amount"
                                        v-validate="'required|max:45'" placeholder="0" class="pt-3">
                                        <template v-slot:prepend-inner>
                                            $
                                        </template> 
                                    </hb-text-field> 
                                    <span v-else>{{totalamount | formatMoney}}</span>
                                </v-row>
                            </template>
                            <template v-slot:content>
                                <v-card elevation="0">
                                    <v-card-title class="d-flex align-center ma-0 pa-0 inv-header-cell">
                                        <v-col cols="6" class="d-flex  hb-font-body-medium align-center justify-start">
                                            <span>Invoice</span>
                                        </v-col>
                                        <v-col cols="3" class="d-flex hb-font-body-medium align-center justify-end">
                                            <span>Balance Due</span>
                                        </v-col>
                                        <v-col cols="3" class="d-flex  hb-font-body-medium align-center justify-end">
                                            <span>Amount Applied</span>
                                        </v-col>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text v-for="(line, index) in invoices_payments"
                                        class="pa-0 ma-0 hb-default-font-size" >
                                        <v-row class="d-flex pa-0 mx-0">
                                            <v-col cols="6" class="d-flex align-center hb-font-caption-medium justify-start">
                                                <span>{{ line.invoice_number }}</span>
                                            </v-col>

                                            <v-col cols="3" class="d-flex align-center hb-font-caption-medium justify-end ">
                                                <span>{{ line.balance  | formatMoney }}</span>
                                            </v-col>

                                            <v-col cols="3" class="d-flex align-center hb-font-caption-medium justify-end">
                                                <hb-text-field v-if="edit" type='Number' condensed box medium v-model="invoices_payments[index].amount"
                                                    v-validate="'required|max:45'" placeholder="0">
                                                    <template v-slot:prepend-inner>
                                                        $
                                                    </template>
                                                </hb-text-field>
                                                <span v-else>{{ line.amount | formatMoney }}</span>
                                            </v-col>

                                        </v-row>
                                        <v-divider></v-divider>

                                    </v-card-text>
                                    <v-container  v-if=" total_amount - totalamount > 0" fluid>
                                    <v-row class="pr-6 justify-end" >
                                        <span class="pr-6  hb-font-header-3-medium ">  Over-payment - PMT</span> <span
                                            class="pr-4 hb-font-header-3-medium d-flex justify-end" v-if=" total_amount - totalamount > 0">{{total_amount - totalamount | formatMoney}}</span>
                                        <span v-else>{{ "00" | formatMoney}}</span>
                                    </v-row>
                                </v-container>
                                </v-card>
                            </template>
                        </hb-expansion-panel>
                    </v-expansion-panels>
                   <div class="pt-4">
                    <v-card class="payment-data hb-elevation-medium hb-border-default"   style="background: white" >
                        <v-container class="payment-data-container d-flex">
                            <v-row no-gutters>
                                <v-col cols="12" sm="4" :class="{'card-options': !$vuetify.breakpoint.xs, 'px-4': $vuetify.breakpoint.xs}">
                                <v-radio-group v-model="payment_type" :mandatory="false" >
                                    <v-radio value="bank_transfer"  >
                                    <template v-slot:label>
                                        <hb-icon class="mr-3" color="#101318">mdi-bank</hb-icon>Bank Transfer
                                    </template>
                                    </v-radio>
                                    <v-radio value="direct_deposit"  >
                                    <template v-slot:label>
                                        <hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon>Direct Deposit
                                    </template>
                                    </v-radio>
                                    <v-radio value="card"  >
                                    <template v-slot:label>
                                        <hb-icon class="mr-3" color="#101318">mdi-credit-card-outline</hb-icon>Credit/Debit
                                    </template>
                                    </v-radio>
                                    <v-radio value="direct_debit">
                                    <template v-slot:label>
                                        <hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon>Direct Debit
                                    </template>
                                    </v-radio>
                                    
                                    <v-radio value="cash" >
                                    <template v-slot:label>
                                        <hb-icon class="mr-3" color="#101318">mdi-cash-multiple</hb-icon>Cash
                                    </template>

                                    </v-radio>
                                    <v-radio value="check" >
                                    <template v-slot:label>
                                        <hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon>Check
                                    </template>

                                    </v-radio> 
                                    <v-radio value="invoice">
                                    <template v-slot:label>
                                        <hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon>Credit Balance
                                    </template>

                                    </v-radio> 
                                </v-radio-group>
                                
                                </v-col>
                                <!-- <v-divider class="pa-0 ma-0" vertical></v-divider> -->
                                
                                <!-- <v-col cols="12" class="ma-0 pa-0" v-if="$vuetify.breakpoint.xs">
                                <v-divider class="pa-0 ma-0"></v-divider>
                                </v-col> -->
                                <v-col cols="12" sm="8" class="payment-method-holder">
                                    
                                    <v-container v-if="payment_type!='creditbalance'">
                                        <span class="hb-font-header-3-medium">{{paymentmethod}}</span>
                                        <div class="pt-5"><span class="pt-5">Transaction Details</span>
                                            <p  class="hb-font-caption pt-3">{{paymentmethod}} Amount Tendered</p>
                                            <hb-text-field
                                                v-model="total_amount"
                                                v-validate="'required|max:45'"  
                                                prepend-inner-icon="mdi-currency-usd" 
                                                placeholder="0">
                                            </hb-text-field>
                                            
                                               
                                                
                                                <v-text-field
                                                    key="ref_name"
                                                    :class="{'custom-field-error' : errors.first('ref_name')}"
                                                    hide-details
                                                    v-model= "ref_name"
                                                    v-validate="'required'"
                                                    data-vv-name="ref_name"
                                                    data-vv-as="Reference Name"
                                                    :error-messages="errors.collect('ref_name')"
                                                    label="Reference Name*"
                                                    id="ref-name"
                                                    name="ref-name"
                                                    autocomplete="cc-ref-name"
                                                    @input="handleInputUpdate('payment', 'ref_name', $event)"
                                                    ></v-text-field>
                                            <v-textarea
                                                :class="{'custom-field-error' : errors.first('notes')}"
                                                rows="2"
                                                hide-details
                                                v-model="notes"
                                                data-vv-name="notes"
                                                :error-messages="errors.collect('notes')"
                                                label="Notes"
                                                id="notes"
                                                name="cnotes"
                                                autocomplete="ccnotes"
                                                @input="handleInputUpdate('payment', 'notes', $event)"
                                                >
                                                </v-textarea>
                                        </div>                                                                
                                    </v-container>
                                    
                                    <v-container v-if="payment_type=='creditbalance'">
                                        <span class="hb-font-header-3-medium">Credit Balance</span>
                                        <div><span class="">Transaction Details</span>
                                        </div>                                                               
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                   </div>
                </div>
            </div>
            <!-- <v-col cols="1">
            <v-divider vertical></v-divider>
           </v-col> -->

        </div>
        <hb-bottom-action-bar @close="CloseNationalAccount">
            <template v-slot:right-actions>
                <hb-btn color="primary"   @click="recordaPayment()"
                    >Record Payment</hb-btn>
                
            </template>
        </hb-bottom-action-bar>
    </div>
</template>
<script>
import api from "../../../../assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "../../../../EventBus.js";
export default {
    name: "Record_A_Payment",
    props: ['contact', 'unit_id', 'property_id'],
    mixins: [notificationMixin],
    data() {
        return {
            payment_type:"bank_transfer",
            pay:[],
            deleteLeaseLoading: false,
            gps_payload: {},
            close_confirm: false,
            panel: [0],
            invoices_payments:[],
            invoice: [],
            edit:"",
            ref_name:"",
            notes: '',
            total_amount : "",
        };
    },
    components: {
    },
    created() {
        EventBus.$on('reset_lease_hb_model', this.openModel);
    },
    watch: {
        edit(){
            this.invoices_payments.forEach(invoices => {
                    invoices.amount = invoices.balance;
                });
            this.total_amount = this.totalamount
               
               
        }

    },    async mounted() {
        await this.setAccountInfo()
    },
    computed: {
        ...mapGetters({
            properties: "propertiesStore/filtered",
            NationalAccount: 'onBoardingStore/getNationalAccount',       
        }),
        calculateLength() {
            let leases = this.contact?.Leases.filter(lease => !lease.end_date)
            return leases?.filter((lease) => {
                return this.filterLeases(lease.Unit.property_id);
            })?.length;
        },
        totalamount(){
            return parseFloat(this.invoices_payments.reduce((sum, invoices_payments) => sum +  parseFloat(invoices_payments.amount), 0)).toFixed(2) 
            
        },
        paymentmethod(){
            switch(this.payment_type){
                    case 'invoice':
                        return 'Credit Balance '
                        break;
                    case 'check':
                        return 'Check '
                        break;
                    case 'cash':
                        return 'Cash '
                        break;
                    case 'direct_debit':
                        return 'Direct Debit '
                        break;
                    case 'card':
                        return 'Credit/Debit '
                        break;
                    case 'direct_deposit':
                        return 'Direct Deposit '
                        break;
                    case 'bank_transfer':
                        return 'Bank Transfer '
                        break;
                    default:
                        return ' '
                }

        },
        computedBusinessAddress() {
            if (
                this.contact.Business &&
                this.contact.Business.Address
            ) {
                const address_1 = this.contact.Business.Address.address
                    ? this.contact.Business.Address.address
                    : "";
                const address_2 = this.contact.Business.Address.address2
                    ? this.contact.Business.Address.address2
                    : "";

                let address = address_1;
                if (address_2 != "") address += " " + address_2;

                address +=
                    ", " +
                    this.$options.filters.formatAddress(
                        this.contact.Business.Address
                    );

                return address;
            } else {
                return undefined;
            }
        },
    },
    destroyed() {
        EventBus.$off('reset_lease_hb_model', this.openModel);
    },
    methods: {
        openModel(payload = {}) {
            this.gps_payload = payload;
            if ('property_ids' in this.gps_payload && this.gps_payload.property_ids.length > 0) EventBus.$emit('reset_gps_bulk_property', this.gps_payload);
            else EventBus.$emit('reset_gps_property', this.gps_payload);
        },
        CloseNationalAccount() {
            if (this.showFinal) {
                this.close_confirm = true
            }
            else {
                this.$emit('close')
            }
        },
        filterLeases(property_id) {
            return this.properties.some((i) => i.id === property_id);
        },
        async recordaPayment(){
            let payment={
                "method": this.payment_type,
                "amount": this.total_amount,
                "notes": this.notes,
                "ref_name": this.ref_name
            }
            let data = {"payment":payment,"invoices_payments":this.invoices_payments}
            try{
             let response = await api.post(this, api.NATIONAL_ACCOUNT + `${this.$route.query.na_id}/payment`, data)
                
            if(response){
                 this.showMessageNotification({
                            type: "success",
                            description: "Your transaction has been processed successfully"
                        });
                        this.CloseNationalAccount()
            }
            }catch(err){ 
                this.showMessageNotification({
                            type: 'error',
                            description: err,
                        });
                    }


    },

        async addPayment(id,index) {
           if(this.pay[index]){

                this.invoices_payments.push({"invoice_id":id.id,"amount":id.balance,"balance":id.balance,"invoice_number":id.invoice_number})
                
            }else{
                
                this.invoices_payments = this.invoices_payments.filter(invoice => invoice.invoice_id !== id.id);

            }
            this.total_amount = parseFloat(this.invoices_payments.reduce((sum, invoices_payments) => sum +  parseFloat(invoices_payments.amount), 0)).toFixed(2)
        },
        setPayment(edit){
            if(!edit){
            }

        },

        async setAccountInfo() {
            let data;
            try {
                await api.get(this, api.NATIONAL_ACCOUNT + `${this.$route.query.na_id}/open-transactions`)
                    .then(async (res) => {
                        this.invoice = res.invoices;

                    });

                await api
                    .get(
                        this,
                        api.NATIONAL_ACCOUNT +
                        `${this.$route.query.na_id}?contact_id=${this.$route.params.contact_id}`
                    )
                    .then(async (res) => {
                        data = res[0];
                    });

            } catch (error) {
                console.log(error, "error");
            }
        },
    },
}
</script>
<style scoped>
.lead-intake-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #f9fafb;
}

.lead-intake-top {
    flex-direction: row;
    align-items: stretch;
    flex-grow: 1;
    overflow: auto;
}

.movein-left {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex: 5;
    overflow: hidden;
    height: 100%;
    box-shadow: 8px 0px 18px rgba(71, 84, 116, 0.2) !important;
    z-index: 202 !important;
}

.movein-right {
    flex: 4;
    display: flex;
    overflow: hidden;
}

.movein-right>>>.v-window {
    height: calc(100% - 20px) !important;
}

.add-padding-overlay-active {
    padding-bottom: 150px !important;
}

.tab-items {
    /* display: flex; */
    flex: 1;
    overflow: auto;
    height: 100%;
    padding-bottom: 5%;
    background-color: #f9fafb !important;
    /* width: -webkit-fill-available; */
}

.footer-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 12px 16px;
    box-shadow: inset 0px 1px 0px rgba(142, 142, 143, 0.15);
    width: 100%;
    border-top: 1px solid #dfe3e8;
    z-index: 1;
}

.theme--light.v-tabs-items {
    background-color: #f9fafb !important;
}

.lease-info-main {
    overflow: hidden !important;
}

.lease-total-overlay {
    position: absolute;
    bottom: 60px;
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 0;
    z-index: 300;
    box-shadow: 0px -4px 10px 3px rgba(71, 84, 116, 0.1);

}

.lease-total-overlay>>>.v-card {
    border-radius: 0;
    padding: 0px !important;
}
</style>
