var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"pa-2",attrs:{"color":"#E0F5F5","width":"100%","elevation":"0"}},[_c('v-expand-transition',[(_vm.expanded)?_c('v-card-text',{staticStyle:{"color":"black"}},[_c('v-row',{staticClass:"move-in-down-arrow",attrs:{"no-gutters":""}},[_c('div',[_c('span',{staticClass:"hb-font-header-3-medium"},[_vm._v("Move-In Date: ")]),_c('span',{staticClass:"hb-font-header-3"},[_vm._v(" "+_vm._s(_vm._f("formatDateTimeCustom")(_vm.summary_data.start_date,"MMM DD, YYYY"))+" ")])]),(_vm.expanded)?_c('HbBtn',{attrs:{"icon":"","mdi-code":_vm.iconCode},on:{"click":_vm.toggleExpansion}}):_vm._e()],1),_c('div',{staticClass:"overall-bill-split"},[(_vm.summary_invoice && _vm.summary_invoice.InvoiceLines)?_vm._l((_vm.summary_invoice.InvoiceLines),function(line,i){return _c('v-row',{key:'rent'+ i,staticClass:"hb-font-body",attrs:{"no-gutters":""}},[(line.Product.default_type == 'rent')?[_c('v-col',[_vm._v(" "+_vm._s(line.Product.name)+" "),(line.Service && line.Service.recurring)?_c('small',{staticClass:"hb-text-light"},[_vm._v("("+_vm._s(_vm._f("formatDate")(line.start_date))+" - "+_vm._s(_vm._f("formatDate")(line.end_date))+")")]):_vm._e()]),_c('v-col',{staticClass:"align-right"},[(line.qty > 1)?_c('span',[_vm._v(" "+_vm._s(line.qty)+" x ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("formatMoney")(line.cost))+" "),(
                    (line.Product.default_type === 'product' ||
                      line.Product.default_type === 'late') &&
                    _vm.step > 2 &&
                    !_vm.invoices[0]?.id
                  )?_c('span'):_vm._e()])]:_vm._e()],2)}):_vm._e(),(_vm.summary_invoice && _vm.summary_invoice.InvoiceLines)?_vm._l((_vm.summary_invoice.InvoiceLines),function(line,i){return _c('v-row',{key:'services'+ i,staticClass:"hb-font-body",attrs:{"no-gutters":""}},[(line.Product.default_type != 'rent')?[_c('v-col',[_vm._v(" "+_vm._s(line.Product.default_type == "insurance" ? "Coverage (" + line.Product.name + ")" : line.Product.name)+" "),(line.Service && line.Service.recurring)?_c('small',[_vm._v(_vm._s(_vm._f("formatDate")(line.start_date))+" - "+_vm._s(_vm._f("formatDate")(line.end_date)))]):_vm._e()]),_c('v-col',{staticClass:"align-right"},[(line.qty > 1)?_c('span',[_vm._v(" "+_vm._s(line.qty)+" x ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("formatMoney")(line.cost))+" "),(
                    (line.Product.default_type === 'product' ||
                      line.Product.default_type === 'late') &&
                    _vm.step > 2 &&
                    !_vm.invoices[0]?.id
                  )?_c('span'):_vm._e()])]:_vm._e()],2)}):_vm._e(),(_vm.summary_invoice)?_c('div',{staticClass:"hb-font-body"},[_c('v-row',{staticClass:"hb-font-body-medium",attrs:{"no-gutters":""}},[_c('v-col',[_vm._v("Subtotal")]),_c('v-col',{staticClass:"align-right"},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.summary_invoice.sub_total)))])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v("Tax")]),_c('v-col',{staticClass:"align-right"},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.summary_invoice.total_tax)))])],1),(_vm.summary_invoice.discounts > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v("Discounts")]),_c('v-col',{staticClass:"align-right"},[_vm._v("-"+_vm._s(_vm._f("formatMoney")(_vm.summary_invoice.discounts)))])],1):_vm._e()],1):_vm._e()],2)],1):_vm._e()],1),_c('v-card-title',{staticClass:"total-balance-due-section",style:(_vm.expanded?'box-shadow: rgba(71, 84, 116, 0.1) 0px -4px 8px 0px;':'')},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"lease-amount-flex hb-font-header-3-medium"},[_c('span',[_vm._v("Move-In Cost:")]),_c('span',[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.summary_invoice.balance)))])]),_c('div',{staticClass:"lease-amount-flex hb-font-body"},[_c('span',[_vm._v("Monthly Rent:")]),_c('span',[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.summary_data.rent)))])])]),(!_vm.expanded)?_c('HbBtn',{attrs:{"icon":"","mdi-code":_vm.iconCode},on:{"click":_vm.toggleExpansion}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }