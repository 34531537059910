<template>

    <div style="background-color: #ffffff;" class="d-flex flex-column communication-center-container" >
      <hb-blade-header
        class="unified-communication-header"
        title="Unified Communications"
        title-icon="mdi-forum"
        half
        back-button-off
        @close="onClose"
      >
        <template v-slot:right>
            <help-juice nav />
        </template>
      </hb-blade-header>
          <div class="content-view py-0 flex-fill d-flex flex-column">
              <v-row>
                <v-col
                  cols="4"
                  xs="6"
                  sm="6"
                  md="5"
                  lg="5"
                  xl="5"
                  class="py-0 px-0"
                  style="max-width:360px;"
                >
                  <tenant-leads-list 
                    @showInteraction="showInteractionDetails" 
                    :updatedInteractor="updatedInteractor"
                    @onOpen="onOpen"
                    @onClose="onClose"
                    :refreshData="refreshData"
                    :contact_id="contact_id"
                  ></tenant-leads-list>
                </v-col>
                <v-col
                  cols="8"
                  xs="6"
                  sm="6"
                  md="7"
                  lg="7"
                  xl="7"
                  class="py-0 px-0 chat-window"
                  style="min-width: calc(100% - 360px);"
                >
                  <chat-window 
                    v-if="chatObj !== null && chatObj !== undefined"
                    @viewContact="showSideBlade"
                    :InteractionObj="chatObj"
                    :adjust-layout="true"
                    :blade_data="blade_data"
                    chatWindowHeight="medium"
                    layout="message-center"
                    @interactorUpdate="updateInteractor"
                    @updateBladeData="updateBladeData"
                  ></chat-window>
                </v-col>
              </v-row>
          </div>
    </div>
    
</template>

<script>
    import { EventBus } from '../../../EventBus.js';
    import TenantAndLeadsList from '../../../components/communication_center/TenantAndLeadsList.vue';
    import ChatWindow from '../../../components/communication_center/ChatWindow.vue';  
    import api from '../../../assets/api.js';
    import { mapActions } from 'vuex';
    export default {
        name: "CommunicationCenter",
        data() {
            return {
                menuOptions: [
                    { label: 'All', key: 1 },
                    { label: 'Email', key: 2 },
                    { label: 'SMS', key: 3 },
                    { label: 'Phone', key: 4 },
                    { label: 'Notes', key: 5 },
                ],
                showAside: false,
                showDrawer: false,
                slideBladeStyle: '',
                contactId: '',
                chatObj: undefined,
                refreshData: false,
                updatedInteractor: undefined,
                re_render: false,
                threadStyle:{
                    height: '100%',
                },
            }
        },
        async created() {
            EventBus.$on('hide_drawer', this.close);  
        },
        destroyed() {
            EventBus.$off('hide_drawer', this.close);
        },
        components: {
            'tenant-leads-list': TenantAndLeadsList,
            'chat-window': ChatWindow,
        },
        props:  ["contact_id", 'blade_data'],
        methods: {
          ...mapActions({
            getMessageCount: 'notificationsStore/getMessageCount'
          }),
          onClose () {
            this.getMessageCount();
            this.$emit('close')
            this.showAside = false;
        },
        
        onOpen () {
          this.refreshData = false;
        },
            showSideBlade (contact_Id) {
            this.contactId = contact_Id
            if (this.$vuetify.breakpoint.lgAndUp) {
                this.showAside = !this.showAside
            } else{
                this.showDrawer = !this.showDrawer
            }
            },
            async updateInteractor(obj) {
            let response = await api.get(this, api.CONTACTS + obj.contact_id + '/interaction-details');
			this.updatedInteractor = response.interactor;
            },

            showInteractionDetails (obj) {
            this.chatObj = obj
            this.myId = obj.id
            },
    
            getWindowSize(){
            var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
            return {x,y}
            },
            updateBladeData(data) {
              this.blade_data = data;
            }
                }
            }
</script>

<style scoped>
    .communication-center-container{
    height: 100%;
    width: 100%;
  }

  .unified-communication-header {
    flex: 0 0 auto;
  }

    .subHeading {
    font-size: 14px;
    vertical-align: top;
    letter-spacing: -0.2px;
    align-content: left;
    margin: 0px;
    }

    .section-content {
    padding-right: 0px !important;
    }

    .side-blade {
    width: 30%;
    border: 1px solid #e2e2e2;
    background-color: #fff;
    box-shadow: 1px 1px 4px 0px #dee5e7;
    }
    .content-view {
    padding-right: 12px;
    padding-left: 12px;
    margin-top: 0 !important;
    border: none;
    box-shadow: none !important;
    }
    .chat-window{
    border-color: rgba(11, 18, 29, 0.1);
    border-style: solid;
    border-width: 0px 1px 0px 1px;
    }
</style>
