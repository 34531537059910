
<template>
  <div  style="overflow: hidden;" class="navigation-panel-container">
    <div class="panel-content">
      <hb-blade-header
        title="Take a Payment"
        title-icon="mdi-currency-usd"
        :back-button-off="true"
        @close="close"
      >
      </hb-blade-header>
      <hb-header full :align-top="true" :padding="false">
        <!-- in order to add access code display properly, you would have to add logic for both space access code and regular access code checks, so I am removing access code display for now because it would not be fully correct to leave it as is -->
        <mini-profile-view
          class="px-6 py-4" 
          v-if="contact?.id"
          :contact-id="contact.id"
          :use-contact="false"
        />
      </hb-header>
      <div class="pa-6 align-stretch" style="overflow: auto;" v-if="summary_panel">
        <payment-process :contactID="contact_id" :propertyID="property_id" paymentSource="TAKE_PAYMENT" ref="processPayment" v-if="!show_receipt"></payment-process>
        <receipt :payment_id="paymentObj.id" :contact="contactObj" @close="close" @makeAnotherPayment="makeAnotherPayment" v-if="show_receipt"></receipt>
      </div>
    </div>

    <v-toolbar flat class="panel-footer position-relative" v-if="!show_receipt">
      <help-juice nav />
      <v-spacer></v-spacer>
      <a class="mr-3" @click="close">Cancel</a>
      <hb-tooltip>
        <template v-slot:item>
            <hb-btn :disabled="isLoading($options.name) || sendPayLink" prepend-icon="mdi-information" color="primary" class="mr-2" @click="generateLink">Send Payment Link</hb-btn>
        </template>
        <template v-slot:body>
            Only for outstanding balance.
        </template>
      </hb-tooltip>
      <hb-btn v-if="inCall && ivrPaymentType === 'card' &&  IvrPaymentEnable && ivrPaymentMethod == 'new' " color="primary" @click="completeSession()" :disabled="!enableIvrProcessPayment" :loading="ivrPaymentButtonLoading">Process Payment  {{ivrTimeout}}</hb-btn>
      <hb-btn v-else color="primary" class="mr-2" @click="checkPaymentsErrors" :loading="disable_payment" :disabled="isLoading($options.name) || disable_payment || getDisablePaymentButton || paymentData.totalNewPaymentDue <= 0 || paymentData.totalDue <= 0 || paymentData.totalNewPaymentPaying <= 0 ">Process Payment</hb-btn>
    </v-toolbar>
    <payments-notification-modal
        v-model="notificationModal"
        notificationType="accept_payments"
    >
    </payments-notification-modal>

    <hb-modal
      size="medium"
      title="Confirm"
      v-model="showPayLinkModal"
      confirmation
    >
      <template v-slot:content>
          <div class="py-4 px-6 hb-font-header-3">
              How do you want to send the payment link?
          </div>
            <hb-checkbox
              v-model="emailCheck"
              label="Email"
              class="mb-2 px-6"
            >
            </hb-checkbox>
            <div class="mb-1 px-6 hb-font-header-3">{{ contact?.email }}<v-divider length="64"></v-divider > </div>
            <hb-checkbox 
              v-if="contact?.Phones[0]?.sms"
              v-model="textCheck"
              label="Text"
              class="mt-4 mb-1 px-6"
            >
            </hb-checkbox>
            <div v-if="contact?.Phones[0]?.sms" class="mb-4 px-6 hb-font-header-3">{{ contact?.Phones[0]?.phone | formatPhone}}<v-divider length="64"></v-divider > </div>
      </template>

      <template v-slot:right-actions>
          <hb-btn :disabled="!emailCheck && !textCheck" @click="sendPayLinkMessage">Send</hb-btn>
      </template>
      
    </hb-modal>
  </div>

</template>
<script type="text/babel">
  import api from '../../../assets/api.js';
  import Charges from '../Charges.vue';
  import Status from '../Messages.vue';
  import Loader from '../../assets/CircleSpinner.vue';
  import SendMessage from '../../includes/SendMessage.vue'
  import moment from 'moment';
  import AuthnetCard from "../PaymentMethods/AuthnetCard";
  import AuthnetAch from "../PaymentMethods/AuthnetAch";
  import Cash from "../PaymentMethods/Cash";
  import Check from "../PaymentMethods/Check";
  import MoneyOrder from "../PaymentMethods/MoneyOrder";
  import Card from "../PaymentMethods/Card";
  import TsysCard from "../PaymentMethods/TsysCard";
  import TsysReader from "../PaymentMethods/TsysReader";
  import TsysAch from "../PaymentMethods/TsysAch";
  import TenantPaymentsAch from "../PaymentMethods/TenantPaymentsAch";
  import TenantPaymentsCard from "../PaymentMethods/TenantPaymentsCard";
  import FatzebraCard from "../PaymentMethods/FatzebraCard.vue";
  import ForteAch from "../PaymentMethods/ForteAch";
  import { EventBus } from '../../../EventBus.js';
  import CollectPayment from '../CollectPayment.vue';
  import PaymentProcess from '../PaymentProcess/Index.vue';
  import Receipt from './Receipt.vue';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { notificationMixin } from  '../../../mixins/notificationMixin.js';
  import PaymentsNotificationModal from '../../payments/PaymentsNotificationModal.vue';
  import axios from 'axios';
  import https from 'https';
  import MiniProfileView from '../LeadIntake/MiniProfileView.vue';

    export default {
        name: 'MakePayment',
        mixins: [ notificationMixin ],
        components: {
            AuthnetCard,
            AuthnetAch,
            Status,
            Loader,
            Cash,
            Check,
            Card,
            MoneyOrder,
            TsysCard,
            TsysReader,
            TsysAch,
            ForteAch,
            TenantPaymentsAch,
            TenantPaymentsCard,
            FatzebraCard,
            Charges,
            CollectPayment,
            SendMessage,
            PaymentProcess,
            Receipt,
            PaymentsNotificationModal,
            MiniProfileView,
        },
        data: () => ({
            show_receipt: false,
            report_data: {},
            disable_payment: false,
            sendMessage: false,
            formErrors: '',
            payment_method: {
                id: '',
                type: '',
                first: '',
                last: '',
                name_on_card: '',
                card_number: '',
                cvv2: '',
                exp_mo: '',
                exp_yr: '',
                save_to_account: 1,
                account_type: '',
                routing_number: '',
                account_number: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                country: ''
            },
            payments: [],
            contactObj : {},
            paymentObj : {},
            billed_months: '',
            payment_methods: [],
            collect_payments: 0,
            payment_type: 'reader',
            alt_payment_type: '',
            step: 1,
            close_confirm: false,
            panels: [0],
            valid: false,
            unit_id: '',
            drawer: false,
            model: null,
            search: null,
            access: [],
            summary_panel: 'property',
            leases: [],
            totalNewPaymentPaying: 0,
            partial_payment: false,
            use_credits: true,
            connections: [],
            IPaddress: '',
            current_charges: {},
            invoices: [],
            notificationModal: false,
            sendPayLink: false,
            showPayLinkModal: false,
            textCheck: false,
            emailCheck: false,
            textMessage: '',
            emailMessage: '',
            selectedSpaces: [],
        }),
        props: ["contact_id", "property_id"],
        async created(){
            this.updateIvrPaymentEnable(true)
            if(this.activeDrawerComponent.type === 'showMakePayment' && this.activeDrawerComponent.show_receipt){
              if(this.activeDrawerComponent.payment_id){
                this.setPayment({property : 'id', propertyValue: this.activeDrawerComponent.payment_id });
                this.paymentObj = JSON.parse(JSON.stringify({id: this.activeDrawerComponent.payment_id}));
              }
              if(!this.contact?.id) this.setContact({contact: this.activeDrawerComponent.contact});
              if(!this.contactObj?.id) this.contactObj = JSON.parse(JSON.stringify(this.activeDrawerComponent.contact));
              this.show_receipt = true;
            }
            EventBus.$on('paymentReader', this.enablePayment);
            await this.fetchAccessData();
            EventBus.$on('reset_take_a_payment_hb_model', this.close);
        },
        destroyed(){
          this.updateIvrPaymentEnable(false)
          EventBus.$off('paymentReader', this.enablePayment);
          this.closeMessageNotification();
          EventBus.$off('reset_take_a_payment_hb_model', this.close);
        },
        computed: {
          inCall() {
              return this.notification.status === 'active';
            },
          ivrTimeout(){
              return this.ivrTimer
          },
          ...mapGetters({
            ivrTimer:'charmCallStore/ivrTimer',
            ivrPaymentButtonLoading:'charmCallStore/ivrPaymentButtonLoading',
            paymentConnectorResponse:'charmCallStore/paymentConnectorResponse',
            enableIvrProcessPayment:'charmCallStore/enableIvrProcessPayment',
            ivrPaymentMethod:'charmCallStore/ivrPaymentMethod',
            IvrPaymentEnable:'charmCallStore/IvrPaymentEnable',
            ivrPaymentType:'charmCallStore/ivrPaymentType',
            paymentData: 'paymentsStore/getPaymentInformation',
            notification:'charmCallStore/notification',
            payment: 'paymentsStore/getPayment', 
            contact: 'paymentsStore/getContact',
            getErrors: 'paymentsStore/getErrors',
            activeDrawerComponent: 'navigationStore/activeDrawerComponent',
            hasPermission: 'authenticationStore/rolePermission',
            getDisablePaymentButton: 'paymentsStore/getDisablePaymentButton',
            getLeaseUnitNumber: 'paymentsStore/getLeaseUnitNumber',
            getLeases: 'paymentsStore/getLeases',
            company: 'authenticationStore/getCompany',
          }),
          computedContactTitle() {
            return (this.contact.first ? this.contact.first : '') + (this.contact.middle ? ' ' + this.contact.middle : '') + (this.contact.last ? ' ' + this.contact.last : '');
          },
          computedContactPhone() {
            if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone){
              return this.contact.Phones[0].phone;
            } else {
              return undefined;
            }
          },
          computedContactPhoneVerified() {
            if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone_verified && this.contact.Phones[0].phone_verified == 1){
                return true;
            } else {
                return false;
            }
          },
          computedContactAddress() {
            if(this.contact.Addresses && this.contact.Addresses[0] && this.contact.Addresses[0].Address){
              return (this.contact.Addresses[0].Address.address ? this.contact.Addresses[0].Address.address : '') + (this.contact.Addresses[0].Address.address2 ? ' ' + this.contact.Addresses[0].Address.address2 + ', ' : '') + this.$options.filters.formatAddress(this.contact.Addresses[0].Address);
            } else {
              return undefined;
            }
          },
          computedRepresentativeName() {
              if (this.contact.Representative) {
                return (this.contact?.Representative?.Contact?.first ? this.contact?.Representative?.Contact?.first : '') + (this.contact?.Representative?.Contact?.last ? ' ' + this.contact?.Representative?.Contact?.last : '');
              }
              return undefined;
          },
        },
        methods:{
          async completeSession(){
            EventBus.$emit('complete-ivr-payment-session');
          },
          ...mapActions({         
            getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject',
            payInvoice: 'onBoardingStore/payInvoice',
            payInvoicePAXLAN: 'onBoardingStore/payInvoicePAXLAN',
            fetchContactLeasesWithInvoices: 'paymentsStore/fetchContactLeasesWithInvoices',
            fetchContactOpenPayments: 'paymentsStore/fetchContactOpenPayments',
            setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent'
          }),

          ...mapMutations({
            updateIvrPaymentEnable:'charmCallStore/updateIvrPaymentEnable',
            setInvoicesObject: 'paymentsStore/setInvoicesObject',
            setCheckErrors: 'paymentsStore/setCheckErrors',
            checkOnlyCredit: 'paymentsStore/checkOnlyCredit',
            setPaymentObject: 'paymentsStore/setPaymentObject',
            setPayment: 'paymentsStore/setPayment',
            setContact: 'paymentsStore/setContact',
            resetPayments: 'paymentsStore/resetPayments',
            setLeaseUnitNumber: 'paymentsStore/setLeaseUnitNumber',
            setRefreshTenantProfile: 'tenantProfile/setRefreshTenantProfile',
          }),

          makeAnotherPayment() {
            this.show_receipt = false;
            if(this.activeDrawerComponent.type === 'showMakePayment') this.setActiveDrawerComponent({ ...this.activeDrawerComponent, show_receipt: false });
          },

            // Flow on adding additional month is as follows :
            /*
              1. Call api to get future invoices of some lease (selected from drop down)
              2. Remove the entries from previous invoices array for this lease which were present previously
              3. Add the new invoices for this lease (result come from api)
              4. Sort the invoices with start date (To pay older invoice first)
              5. Calculate amounts / credits utilization
            */

            resetPaymentInfo(){
              this.resetPayments();
            },

            async enablePayment(response) {
              this.disable_payment = false;
              if(response && response.error) {
                this.showMessageNotification({id: this.$options.name, list: [response.error]});
              } else if(response && response.data && response.data.msg) {
                this.showMessageNotification({ type: 'success', description: response.data.msg.text });
                if(response.data.payment && response.data.payment.id) {
                  this.setPaymentObject({payment: {...this.payment, ...response.data.payment}});
                  this.paymentObj = JSON.parse(JSON.stringify(this.payment));
                  this.contactObj = JSON.parse(JSON.stringify(this.contact));
                  this.show_receipt = true;
                  if(this.activeDrawerComponent.type === 'showMakePayment') this.setActiveDrawerComponent({ ...this.activeDrawerComponent, show_receipt: true, contact: this.contact, payment_id: this.payment.id });
                } else {
                  await this.$refs.processPayment.initPaymentProcess(this.property_id, this.contact_id);
                }
                EventBus.$emit('payment_saved');
                EventBus.$emit('reset_profile_tasks');
                this.collect_payments++;
              }
            },

            async fetchAccessData(){
                let r = await api.get(this, api.CONTACTS + this.contact_id + '/access?property_id=' + this.property_id);
                this.access = r.access;
            },
            async fetchPropertyConnections(device_id) {
              let r = await api.get(this.$app, api.PROPERTIES + this.property_id + "/connections/address?device_id=" + device_id);
              this.IPaddress = r.IPaddress;
              this.lan = r.lan;
            },
            close(){
                this.$emit('close');
                EventBus.$emit('fetch_unit_gate_status');
                this.setLeaseUnitNumber('resetUnitNumber');
            },

          checkPaymentsErrors() {
              //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
              
              if (this.hasPermission("accept_payments")){
                this.checkOnlyCredit();
              } else {
                this.notificationModal = true;
              }
            },

            async processPayment() {
              await this.setRefreshTenantProfile(false);
              EventBus.$emit('disable_reader_dropdown');
              this.disable_payment = true;
              const paymentInfo = await this.getTransformedPaymentObject({
                id: this.$options.name,
                formErrors: this.formErrors
              });
                            
              if(paymentInfo.errors && paymentInfo.errors.length) {
                this.showMessageNotification({id: this.$options.name, list: paymentInfo.errors});
                this.disable_payment = false;
                EventBus.$emit('enable_reader_dropdown');
                return;
              }

              if(paymentInfo && paymentInfo.payment) {
                let response = {};
                let payloadResponse;
                let lanResponseData;
                let payment_id;
                let responseData;
                //Fetch Device IP and Port for Lan Connection
                if(paymentInfo?.paymentMethod?.device_id)
                  await this.fetchPropertyConnections(paymentInfo.paymentMethod.device_id);
                try {
                    if(paymentInfo.paymentMethod && paymentInfo.paymentMethod.device_id && this.lan == 1){

				  // Create an instance of the HTTPS agent with certificate verification enabled.
                      const certData = `-----BEGIN CERTIFICATE-----
MIIDzTCCArWgAwIBAgIBAjANBgkqhkiG9w0BAQsFADBtMQswCQYDVQQGEwJVUzEL
MAkGA1UECAwCRkwxDDAKBgNVBAcMA0pBWDEMMAoGA1UECgwDUEFYMRQwEgYDVQQL
DAtJTlRFR1JBVElPTjEfMB0GA1UEAwwWUEFYVVMgVEVSTUlOQUwgUk9PVCBDQTAe
Fw0xODA4MDYwNzMyMTJaFw0yODA4MDMwNzMyMTJaMGoxCzAJBgNVBAYTAlVTMQsw
CQYDVQQIDAJGTDEMMAoGA1UECgwDUEFYMRQwEgYDVQQLDAtJTlRFR1JBVElPTjEq
MCgGA1UEAwwhUEFYVVMgVEVSTUlOQUwgU0VSVkVSIENFUlRJRklDQVRFMIIBIjAN
BgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwbJg+g0N/M+ohU4sTpNHY25j0tH3
6f6kk5M564g6/kBv8WWL/4QnpLHfGVFdBC+P30JW9QjOGJj5OY5suQm0xqs6/rD1
qb1cWbBaeTvOMmx+79uIuQweRAfbAOdc3RdKSz3nR/IfZ1059FG23/8IF9/pRXuM
oeFl4MzWwoxkqJ24Q216aD+fJvGNjoajuBbnyM+5WQDRqBE+GI04ElWDDLLft/Kx
cQwL/gjxFeGSmDg88yHPcimcIulnGnmc6M3SV5RbGVHbCtOwzwjQdUndom9lDZ82
VkBPUaYgiMnFzbbYF9FHFP2xD93qvWrBpc5GGQleDsH8LLHsXbZdPbvdnwIDAQAB
o3sweTAJBgNVHRMEAjAAMAsGA1UdDwQEAwIFoDAdBgNVHQ4EFgQUuWH25D/quAew
5neUSAjaqxe6LCUwHwYDVR0jBBgwFoAUKyQA82I7zBIeEmhqHya07aPrLHYwHwYD
VR0RBBgwFoIIKi5wYXgudXOHBH8AAAGHBMCoYnIwDQYJKoZIhvcNAQELBQADggEB
ADBQHlhlEHzmyVLiAoInaxbP7s8hN29VcWXUQXo5VrbfWCB26zfY0mFgZezMa7VB
dzzflJ/XeqDs+HgQ5KQ5cXL4PO+jpFvPLPj7wfy02YdyjuKu/EDMkrxLXwJm8TJY
qeVd/W5phCxqXc/+5jvvrGGdOTd+tgpz/DndKl4mdboqWeqCJxVWbxZez2FDrUzP
cPHhEt+gfJFr2ARYhPwf7jD5/97YV2ULeYxoOkr+qF5GdTdSxn944FM8l8ZGrRpe
rkhYcw+B0V6MgdoFGhkBDXbos0KiUUbLVgCyf+ewUQuc1+2q9++s+amJ0svydr1V
0gVZN3O9nql8n0IcpWtsyvQ=
-----END CERTIFICATE-----`;

						const httpsAgent = new https.Agent({
						  ca: [certData],
						});

						// Configure Axios to use the custom HTTPS agent.
						const instance = axios.create({
						  httpsAgent
						});
                    const endpoint = 'https://' + this.IPaddress + '?';
                    //Get PAX Request Payload from HB Backend server
                    payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",paymentInfo);
                    payment_id = payloadResponse.payment.id;
                    paymentInfo.payment.id = payment_id;
			              // Make the GET request using the custom Axios instance.
			              lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
                    paymentInfo.payment.PAXResponse = lanResponseData;
                    responseData = await this.payInvoicePAXLAN(paymentInfo);
                  } else {
                   responseData = await this.payInvoice(paymentInfo);
                  }
                  response = {
                    data: {
                      msg: {
                        id: this.$options.name,
                        text: responseData.msg,
                      },
                      payment: responseData.payment
                    }
                  };

                  EventBus.$emit('lease_edited');
                  EventBus.$emit('refresh_lease_tenants');
                  EventBus.$emit('payment_methods_updated');
                  EventBus.$emit('enable_reader_dropdown');

                } catch(err){
                  response = {
                    error: err,
                    contact_id: paymentInfo.contact_id
                  };
                  if(paymentInfo.paymentMethod && paymentInfo.paymentMethod.device_id && this.lan == 1)
                  {
                    responseData = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
                  }
                } finally {
                  EventBus.$emit('enable_reader_dropdown');
                  this.enablePayment(response);
                  await this.setRefreshTenantProfile(true);
                }
              }
            },

            async generateLink(){
              let uniqueSpaces = new Set(this.getLeaseUnitNumber);
              this.selectedSpaces = [...uniqueSpaces]
              await this.payNowLinkEmail();
              await this.payNowLinkText();
              this.showPayLinkModal = true;
            },
            async payNowLinkText(){
              let data = {
                unit_number : this.selectedSpaces.length ? this.selectedSpaces : 'Tenant',
                communication_type : 'Text'
              }
              let r;
              try{
                r = await api.get(this, api.CONTACTS + this.contact_id + '/one-time-link', data);
              }catch(error){
                console.log('error',error);
              }
              let pay_message = `Action Required - Secure Payment Link. \n Link: ${r.link}`
              this.textMessage = pay_message;
            },
            async payNowLinkEmail(){
              let data = {
                unit_number : this.selectedSpaces.length ? this.selectedSpaces : 'Tenant',
                communication_type : 'Email'
              }
              let response = await api.get(this, api.CONTACTS + this.contact_id + '/one-time-link', data);
              let link = response.link;
              let buttonHtml = `<a target="_blank" rel="noopener noreferrer nofollow" href="${link}">Pay Now</a>`
              this.emailMessage = `<p>Dear ${this.contact.first} ${this.contact.last},</p>
                                  <p>We hope this message finds you well. You have a payment associated with your storage rental at ${this.company.name}. To expedite the process, we've generated a secure payment link for your convenience.</p>
                                  <h3>Payment Instructions:</h3>
                                  <ol>
                                      <li>Click on the following secure payment link: <b>${buttonHtml}</b></li>
                                      <li>Enter your credit card information</li>
                                      <li>Follow the on-screen instructions to complete the payment securely.</li>
                                  </ol>
                                  <p><strong>Important Notes:</strong></p>
                                  <ul>
                                      <li>This payment link will expire in 24 hours, so please complete the payment promptly.</li>
                                      <li>If this payment is not currently due or if you have already processed it, kindly disregard this message.</li>
                                  </ul>
                                  <p>Thank you for your prompt attention to this matter. We appreciate your cooperation.</p>
                                  <p>Best regards,<br>
                                  ${this.company.name}<br>`;
            },
            async sendPayLinkMessage(){
              if(this.emailCheck === true){
                var data = {
                  message: this.emailMessage,
                  subject: 'Action Required - Secure Payment Link Inside',
                  space: 'Tenant',
                  send_email: true,
                  sms: [],
                  upload_id: null,
                  primary_contact_id:this.contact_id,
                  property_id:this.property_id
                }
                try {
                  await this.handleSendEmail(data)
                  this.showMessageNotification({
                    type: 'success', 
                    description: 'The payment link sent via email is successfully'
                  });
                this.close();

                } catch (error) {
                  this.showMessageNotification({
                    type: 'error',
                    description: 'The Email attempted either does not exist, does not allow sending email, or is blocked by a networking issue.'
                  });
                }
              }
              if(this.textCheck === true){
                var data = {
                  message: this.textMessage,
                  subject: ' ',
                  space: 'Tenant',
                  send_email: false,
                  sms: [this.contact?.Phones[0]?.id],
                  primary_contact_id: this.contact_id,
                  property_id:this.property_id
                }
                try {
                  await this.handleSendEmail(data)
                  this.showMessageNotification({
                    type: 'success', 
                    description: 'The payment link sent via text is successfully'
                  });

                } catch (error) {
                  this.showMessageNotification({
                    type: 'error',
                    description: 'The Text attempted either does not exist, does not allow sending text, or is blocked by a networking issue.'
                  });
                }
              }
              this.showPayLinkModal = false;
              this.close();
            },
            async handleSendEmail(data){
              return api.post(this, api.CONTACTS + this.contact.id + '/send-message', data)
            },
        },
        watch: {
          paymentConnectorResponse(payload){
            if(!payload) return
            if(payload.status == 200){
                  let    response = {
                    data: {
                      msg: {
                        id: this.$options.name,
                        text: payload.msg,
                      },
                      payment: payload.payment
                    }
                  };

                  EventBus.$emit('lease_edited');
                  EventBus.$emit('refresh_lease_tenants');
                  EventBus.$emit('payment_methods_updated');
                  this.enablePayment(response);
                }else{
                 let response = {
                    error: payload.msg,
                  };
                  this.enablePayment(response);
                }
          },
          getErrors(value) {
            if(value === false) {
              this.processPayment();
              this.setCheckErrors({ value: null });
            }
          },
          getLeases: {
            handler(value){
              let activeLeases = value.filter(item => item.end_date === null);
              if(activeLeases.length > 1){
                this.sendPayLink = true;
              };
              if(this.getLeaseUnitNumber.length > 0 || activeLeases.length === 1){
                for (let index = 0; index < activeLeases.length; index++) {
                  if (activeLeases[index].AdvanceInvoices.length > 0) {
                    this.sendPayLink = true;
                    break;
                  };
                  if (activeLeases[index].AdvanceInvoices.length === 0) {
                    this.sendPayLink = false;

                  }
                }
              }
            },
            deep: true
          },
          getLeaseUnitNumber(value){
            let activeLeases = this.getLeases.filter(item => item.end_date === null);
            if(value.length > 0 || activeLeases.length === 1){
              this.sendPayLink = false;
            }else {
              this.sendPayLink = true;
            }
          }
        },
    }

</script>

<style scoped>

  .navigation-panel-container{
    background: #F9FAFB;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
  }


  .panel-content {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .user-info,
  .nav-drawer-header {
    background: white;
    flex: 0 1;
  }

  .navigation-panel-container .v-expansion-panel{
    margin-bottom: 10px;
    margin-top: 0;
    border: 1px solid #DFE3E8;
  }

  .info-panel{
    flex: 1 0;
    padding: 30px 50px;
    background-color: #F9FAFB;
    border-left: 1px solid #E1E6EA;
  }
  .walkthrough-body{
    flex: 1;
  }
  .section-header{
    background: white;
  }

  .user-info{
    padding-left: 50px;
    padding-right: 5px;
    background-color: white;
  }


  .v-expansion-panel-content .form-label{
    background: #F4F6F8;
    padding: 20px;

  }

  .v-expansion-panel-content .form-label-active{
    background: #EFFAFA;
    padding: 20px;

  }

  .v-expansion-panel-content .form-label-active h3{
      color: #00848E;
  }

  .invoice_display{
    padding:  10px 0px;
    font-size: 14px;
  }

  .invoice-line-row{
    margin-bottom: 0;
    padding: 0px 20px;
  }
  .invoice-line-row .col {
    padding: 0;
  }

  .invoice-total-row >.col{
    padding: 10px;
  }

  .invoice-row .caption{
    color: #637381
  }

  .invoice-row > .col{
    padding-bottom: 0;
  }

  .invoice-line-row.total{
    border-top: 1px solid #C4CDD5;
    margin-top: 20px;
  }
  .invoice-line-row.total > .col{
    padding-top: 15px;
  }
  .payment-data{
    background: white;
    border: 1px solid #DFE3E8;
    margin-bottom: 10px;
    border-radius: 0;
  }

  .payment-data-header .v-input--checkbox,
  .form-label .v-input--checkbox{
    margin-top: 0;
    padding-top: 0;
  }

  .payment-data-header{
    border-bottom: 1px solid #DFE3E8;
    padding: 15px 20px;
    margin-bottom: 0;
  }
  /*
  .autopay-item{
    background-color: white;
  }
  */
  .auto-debit-placeholder {
    border: 2px dashed #C4CDD5;
    border-radius: 4px;
    padding: 15px;
  }
  /*
  .payment-amount-input input{
    padding: 0;
  }
  */

  /*.container.payment-data-container {*/
  /*  padding: 0 12px;*/
  /*}*/

  /*.card-options{*/
  /*  border-right: 1px solid #C4CDD5;*/
  /*  padding: 10px;*/
  /*  font-size: 14px;*/
  /*}*/

  /*.payment-method-holder{*/
  /*  padding: 10px;*/
  /*}*/


</style>
