<template>
  <div class="lease-section px-6">

    <v-row justify="center" align="center" v-if="loading || loadLeadScreen">
      <v-col class="text-center ma-12">
        <v-progress-circular indeterminate color="primary" ></v-progress-circular>
      </v-col>
    </v-row>
    <v-form v-else>
    <div class="lead-Title-class" v-if="!reservation?.id && !lead?.lead_type && !lead?.id">
      <div class="hb-font-header-2-medium mt-n1" >Lead, Reservation, Move-In </div>
      <!-- <hb-link v-if="!contact.id"  @click="resetForm"> Reset Form </hb-link> -->
    </div>

	  <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error" class="mb-2"></status>

	  <v-expansion-panels v-model="panel" multiple>
        <lead-on-board v-if="(!!reservation?.id || !!lead?.lead_type || lead?.id) && location != `create_new_lead` && !!location" :active_reservation="!!reservation?.id ? reservation : configuration_unit " :lead="lead" :key="reservation.id"></lead-on-board>

        <hb-expansion-panel right-cols="6" left-cols="6" no-top-margin>
        
          <template v-slot:title>
            Lead Information
          </template>
          <template v-if="!isReturningContact" v-slot:actions>
            <HbCheckbox @click.native.stop="displayRentAsBusinessModal"
              label="Rent as a Business"
              v-model="isRentAsBusiness"
            />
          </template>
          <template v-slot:content >
            <hb-form 
              v-if="!reservation?.id && !lead?.lead_type && !lead?.id"
              label="Lead Interaction Information"
            >
             <LeadInteractionOptions @interactionSelected="setInteractionType" :showCharmOption="showCharmOption"></LeadInteractionOptions>
             <div  v-if="!hideScript">
              <p class = 'script-description' >Follow this script to gather information about the customer.</p>
              <div v-html="scriptContent" class="list-style-override">
              </div>
            </div>
            </hb-form>

            <!-- <hb-form
              label="Interaction Type"
              description="Select the type of interaction."
            > //Temporarily commented, needed for Lead flow revamp
            <HbSelect
                condensed
                box
                medium
                v-model="selectField"
                v-validate.disable="'max:45'"
                :items="interactionType"
                data-vv-scope="default"
                id="type"
                name="type"
                :error="errors.collect('default.type').length > 0"
                placeholder="Select"
            />  //Temporarily commented, needed for Lead flow revamp  -->
       
            <!-- <div  v-if="true">
              <p class = 'script-description' >Follow this script to gather information about the customer.</p>
              <div v-html="scriptContent" class="list-style-override">
              </div> //Temporarily commented, needed for Lead flow revamp

            </div> -->

              <!-- <HbTextField
                v-model="form.contact.email"
                v-validate="{ max:45, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
                placeholder="Enter Email"
                id="lead-email"
                name="lead-email"
                data-vv-scope="general"
                data-vv-name="email"
                data-vv-as="Email"
                autocomplete="cc-lead-email"
                :error="errors.collect('general.email').length > 0"
                :autofocus="form && form.contact && form.contact.email && form.contact.email.length === 0"
                @blur="checkEmail"
              /> -->
            <!-- </hb-form> -->
            <hb-form
              label="Email"
              required
            >
              <template v-if="isReturningTenant && isRentAsBusiness" v-slot:tooltipBody>
                <div style="width: 250px">
                  Business Email can only be edited through the Tenant Profile by authorized users.
                </div>
              </template>
              <HbTextField
                v-model="form.contact.email"
                v-validate="{ max:100, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
                :placeholder="isRentAsBusiness ? 'Business Email' : 'Enter Email'"
                id="lead-email"
                name="lead-email"
                data-vv-scope="general"
                data-vv-name="email"
                :data-vv-as="isRentAsBusiness ? 'Business Email' : 'Email'"
                autocomplete="cc-lead-email"
                :error="errors.collect('general.email').length > 0"
                :autofocus="form && form.contact && form.contact.email && form.contact.email.length === 0"
                @blur="checkEmail"
                :disabled="isReturningTenant && isRentAsBusiness ? true : false"
              />
            </hb-form>
            <hb-form
              label="Name"
              required
              full
            >
              <template v-if="isReturningTenant && isRentAsBusiness" v-slot:tooltipBody>
                <div style="width: 250px">
                  Business Name can only be edited through the Tenant Profile by authorized users.
                </div>
              </template>
              <v-row no-gutters>
                <v-col cols="12" sm="6" class="ma-0 pa-0">
                  <HbTextField
                    v-model.lazy="form.contact.first"
                    v-validate="'required|max:45'"
                    :placeholder="isRentAsBusiness ? 'Business Name' : 'First'"
                    id="first"
                    name="first"
                    data-vv-scope="general"
                    :data-vv-as="isRentAsBusiness ? 'Business Name' : 'First Name'"
                    :error="errors.collect('general.first').length > 0"
                    autocomplete="cc-lead-first-name"
                    @blur="checkName"
                    :full="$vuetify.breakpoint.smAndUp"
                    :disabled="isReturningTenant"
                  />
                </v-col>
                <v-col v-show="!isRentAsBusiness" cols="12" sm="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.smAndUp, 'mt-3' : $vuetify.breakpoint.xs}">
                  <HbTextField
                    v-model.lazy="form.contact.last"
                    v-validate="isRentAsBusiness ? '' : 'required|max:45'"
                    placeholder="Last"
                    id="last"
                    name="last"
                    data-vv-scope="general"
                    data-vv-as="Last Name"
                    :error="errors.collect('general.last').length > 0"
                    autocomplete="cc-lead-last-name"
                    @blur="checkName"
                    :full="$vuetify.breakpoint.smAndUp"
                    :disabled="isReturningTenant"
                  />
                </v-col>
              </v-row>
            </hb-form>

            <hb-form
              v-for="(phone,i) in form.contact.Phones" :key="'phone_' + i"
              :label="form.contact.Phones.length > 1 ? 'Phone ' + (i + 1) : 'Phone'"
              :required="i === 0"
              full
            >
              <template v-if="isReturningTenant && isRentAsBusiness" v-slot:tooltipBody>
                <div style="width: 250px">
                  Business Phone can only be edited through the Tenant Profile by authorized users.
                </div>
              </template>
              <div class="d-flex align-top">
                
                <v-col :cols="form.contact.Phones.length > 1 ? 11 : 12" class="ma-0 pa-0">
                  <v-row no-gutters>
                    <v-col cols="12" lg="3" class="ma-0 pa-0">
                      <HbSelect
                        v-model="form.contact.Phones[i].type"
                        v-validate="i === 0 ? 'required' : null"
                        :items="phone_types"
                        placeholder="Type"
                        data-vv-scope="general"
                        :data-vv-name="'type' + i"
                        data-vv-as="Phone Type"
                        autocomplete="hb-cc-lead-phone-type"
                        :error="errors.collect('general.type' + i).length > 0"
                        :clearable="false"
                        :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                      <hb-autocomplete
                        v-model="form.contact.Phones[i].code"
                        v-validate="i === 0 ? 'required' : null"
                        :items="countryCodesList"
                        placeholder="Code"
                        :id="'phone_code_' + i"
                        :name="'phone_code_' + i"
                        data-vv-scope="general"
                        :data-vv-name="'phone_code_' + i"
                        data-vv-as="Phone Code"
                        autocomplete="hb-cc-lead-phone-code"
                        :error="errors.collect('general.phone_code_' + i).length > 0"
                        @change="checkValidPhone(form.contact.Phones[i].code, form.contact.Phones[i].number, i)"
                        @focusout="checkValidPhone(form.contact.Phones[i].code, form.contact.Phones[i].number, i)"
                        :clearable="false"
                        :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                      >
                        <template v-slot:prepend-inner>
                          +
                        </template>
                        <template v-slot:item="data">
                          +{{data.item}}
                        </template>
                      </hb-autocomplete>
                    </v-col>
                    <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                    
                      <HbTextField
                        v-model="form.contact.Phones[i].number"
                        v-validate="'max:45'"
                        v-mask="phoneMask(form.contact.Phones[i].code)"
                        placeholder="Phone Number"
                        :id="'lead-phone-' + i"
                        :name="'lead-phone-' + i"
                        data-vv-scope="general"
                        :data-vv-name="'lead-phone-' + i"
                        data-vv-as="Phone Number"
                        autocomplete="hb-cc-lead-phone-number"
                        :error="(errors.collect('general.lead-phone-' + i).length > 0 || invalidPhones.indexOf(i) >= 0)"
                        @blur="checkPhone($event.target.value, form.contact.Phones[i].code,i)"
                        @change="checkValidPhone(form.contact.Phones[i].code, form.contact.Phones[i].number, i)"
                        @focusout="checkValidPhone(form.contact.Phones[i].code, form.contact.Phones[i].number, i)"
                        :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                      />
                    </v-col>
                    <v-col cols="12" lg="2" :class="{'pl-2' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                      <HbCheckbox
                        v-model="form.contact.Phones[i].sms"
                        label="SMS"
                        :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer v-if="form.contact.Phones.length > 1"></v-spacer>
                <v-col
                  cols="1"
                  v-if="form.contact.Phones.length > 1"
                  class="ma-0 pa-0"
                  align="right"
                >
                  <hb-icon
                    v-if="!isReturningTenant || !isRentAsBusiness"
                    @click="removePhone('contact', i)"
                    class="hand"
                  >
                    mdi-close
                  </hb-icon>
                </v-col>
              </div>
              <div
                v-if="i === form.contact.Phones.length - 1"
                class="mt-4 mb-1"
              >
                <hb-link v-if="!isReturningTenant || !isRentAsBusiness" @click="addPhone()">+ Add Phone Number</hb-link>
              </div>
            </hb-form>
            <hb-form
            v-for="(address,i) in form.contact.Addresses" :key="'contact_address' + i"
            :label="form.contact.Addresses.length > 1 ? 'Address ' + (i + 1) : 'Address'"
            full
            >
            <template v-if="isReturningTenant && isRentAsBusiness" v-slot:tooltipBody>
              <div style="width: 250px">
                Business Address can only be edited through the Tenant Profile by authorized users.
              </div>
            </template>
            <div class="d-flex align-top">

            <v-col :cols="form.contact.Addresses.length > 1 ? 11 : 12" class="ma-0 pa-0">
            <address-autofill
              class="mt-2"
              v-model="autofillData"
              @input="addressDataSet($event, i)"
            >
            </address-autofill>
            <HbAutocomplete
            :items="countriesListMixin"
            v-model="form.contact.Addresses[i].Address.country"
            label="Country"
            :id="'address_country_' + i"
            data-vv-scope="general"
            data-vv-as="country"
            :data-vv-name="'country' + i"
            :name="'country' + i"
            @change="setCountry(i)"
            :disabled="isReturningTenant && isRentAsBusiness ? true : false"
            >
            <template v-slot:selection="data">
            {{data.item}}
            </template>
            <template v-slot:item="data">
            {{data.item}}
            </template>
            </HbAutocomplete>

            <HbTextField
            v-model="form.contact.Addresses[i].Address.address"
            v-validate="'max:45'"
            placeholder="Street"
            :id="'address' + i"
            :name="'address' + i"
            data-vv-scope="general"
            :data-vv-name="'address' + i"
            data-vv-as="Street"
            :autocomplete="'cc-lease-address-' + i"
            :disabled="isReturningTenant && isRentAsBusiness ? true : false"
            />

            <HbTextField
            v-model="form.contact.Addresses[i].Address.address2"
            v-validate="'max:45'"
            placeholder="Suite / Apt"
            :id="'address2' + i"
            :name="'address2' + i"
            data-vv-scope="general"
            :data-vv-name="'address2' + i"
            data-vv-as="Suite / Apt"
            :autocomplete="'cc-lease-address2-' + i"
            :disabled="isReturningTenant && isRentAsBusiness ? true : false"
            />

            <v-row no-gutters>

            <v-col cols="12" lg="3" class="ma-0 pa-0">
            <HbTextField
              v-model="form.contact.Addresses[i].Address.zip"
              placeholder="ZIP Code"
              :id="'zip' + i"
              :name="'zip' + i"
              data-vv-scope="general"
              :data-vv-name="'zip' + i"
              data-vv-as="ZIP Code"
              :autocomplete="'cc-lease-zip-' + i"
              :disabled="isReturningTenant && isRentAsBusiness ? true : false"
            />
            </v-col>
            <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
            <HbAutocomplete
            :items="stateListMixin[i] || []"
            v-model="form.contact.Addresses[i].Address.state"
            item-text='name'
            item-value='value'
            label="State"
            single-line
            :id="'address_state_' + i"
            data-vv-scope="general"
            data-vv-as="state"
            :data-vv-name="'state' + i"
            :name="'state' + i"
            @change="setState(i)"
            :disabled="isReturningTenant && isRentAsBusiness ? true : false"
            >
            </HbAutocomplete>
            </v-col>
            <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
            <HbAutocomplete
              :items="cityListMixin[i] || []"
              v-model="form.contact.Addresses[i].Address.city"
              label="City"
              single-line
              :id="'address_city_' + i"
              data-vv-scope="general"
              data-vv-as="city"
              :data-vv-name="'city' + i"
              :name="'city' + i"
              :disabled="isReturningTenant && isRentAsBusiness ? true : false"
              @keydown.enter="setCityList({event: $event, country: form.contact.Addresses[i].Address.country, state:form.contact.Addresses[i].Address.state, cityList: cityListMixin, index: i, toValidate: true})"
              >
              <template v-slot:selection="data">
                {{data.item}}
              </template>
              <template v-slot:item="data">
                {{data.item}}
              </template>
            </HbAutocomplete>
            </v-col>

            </v-row>

            </v-col>

            <v-spacer v-if="form.contact.Addresses.length > 1"></v-spacer>

            <v-col
            cols="1"
            v-if="form.contact.Addresses.length > 1"
            class="ma-0 pa-0"
            align="right"
            >
            <hb-icon
            @click="removeLocation(i)"
            class="hand"
            >
            mdi-close
            </hb-icon>
            </v-col>

            </div>

            <div
            v-if="i === form.contact.Addresses.length - 1 && !isRentAsBusiness"
            class="mt-4 mb-1"
            >
            <hb-link @click="addLocation">+ Add Address</hb-link>
            </div>

            </hb-form>
            <hb-form
              label="Notes"
              full
              :divider="false"
            >
              <HbTextField
                v-model="form.lead.content"
                v-validate="'max:1000'"
                placeholder="Enter Notes"
                id="lead-content"
                name="lead_content"
                data-vv-scope="lead"
                data-vv-as="Notes"
                autocomplete="cc-lead-content"
                :error="errors.collect('lead.lead_content').length > 0"
                full
              />
            </hb-form>
          </template>

        </hb-expansion-panel>

        <hb-expansion-panel>
          <template v-slot:title>
            Lead Questions
          </template>
          <template v-slot:content>

          <hb-form
            label="Preferred storage location?"
            required
          >
              <HbSelect
                v-model="form.lead.property_id"
                :clearable="false"
                :items="properties"
                item-text="name"
                item-value="id"
                v-validate="'required'"
                placeholder="Select Property"
                name="lead_property"
                data-vv-scope="general"
                data-vv-as="Property Interest"
                :error="errors.collect('general.lead_property')?.length > 0"
                :disabled="disableLeadQuestions"
              />
          </hb-form>
          <hb-form label="Preferred Features and Amenities?">
              <hb-combobox
                  v-model="filterSpace.amenities"
                  :items="amenities_items"
                  label="Features and Amenities"
                  :disabled="disableLeadQuestions"
              ></hb-combobox>
          </hb-form>
          <hb-form
              label="Space Interest?"
            >
            <v-row no-gutters>
              <v-col>
                <hb-select
                  v-model="filterSpace.type"
                  :items=getUnitTypes()
                  label="Type"
                  :disabled="disableLeadQuestions"
                  item-text="display_name"
                  item-value="unit_type_id"
                ></hb-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <hb-select
                  v-model="filterSpace.size"
                  :items="size_items"
                  label="Size"
                  :disabled="disableLeadQuestions"
                ></hb-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <hb-select
                  v-model="filterSpace.price"
                  :items="['< $50', '$50 - $100', '$100 - $150', '$150 - $300', '> $300']"
                  label="Price"
                  :disabled="disableLeadQuestions"
                ></hb-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <hb-select
                  v-model="filterSpace.floor"
                  :items="floor_items"
                  label="Floor"
                  :disabled="disableLeadQuestions"
                ></hb-select>
              </v-col>
            </v-row>
          </hb-form>
            <!-- <hb-form
              label="First Contact"
              :required="contact_type === 'lead'"
            >
              <HbSelect
                v-model="form.lead.method"
                :items="methods"
                v-validate="contact_type === 'lead' ? 'required' : ''"
                placeholder="Select Method"
                name="method"
                data-vv-scope="lead"
                data-vv-as="First Contact"
                :error="errors.collect('lead.method').length > 0"
              />
            </hb-form> -->

            <!-- <hb-form label="How Did You Find Us?">
              <HbSelect
                v-model="form.lead.source"
                :items="sources"
                placeholder="Select Source"
                name="source"
                data-vv-scope="lead"
                data-vv-as="How Did You Find Us?"
              />
            </hb-form> -->

            <hb-form label="When do you need storage?">
              <div style="margin-top:-23px;">
                <HbDatePicker
                  v-model="form.lead.move_in_date"
                  @click:clear="form.lead.move_in_date = null"
                  label="MM/DD/YYYY"
                  :clearable="true"
                  clear-icon="mdi-close-circle"
                  :solo="false"
                  :single-line="true"
                  :default_today="true"
                  data-vv-as="move_in_date"
                />
              </div>
            </hb-form>

            <hb-form label="How long will you need storage?">
              <HbSelect
                v-model="form.lead.length_of_stay"
                :items="length_of_stays"
                placeholder="Select Length of Stay"
              />
            </hb-form>
            <hb-form v-for="(leadQuestion,index) in leadQuestions" 
              :key="leadQuestion.id" 
              :label="leadQuestion.question.question"
              :required="leadQuestion.question.required"
              :description="leadQuestion.question.answers.length > 0 && leadQuestion.question.required ? 'If no answer is given, select N/A.' : ''">
              
              <HbSelect
                class="pb-2"
                v-if="leadQuestion.question.answers.length > 0"
                v-model="leadAnswersOption[index]"
                :items="leadQuestion.question.answers"
                item-text="option"
                :item-value="response"
                placeholder="Select Answer"
                data-vv-scope="lead"
                return-object
              >
              </HbSelect>
              <HbTextField
                v-else
                v-model="leadQuestionAnswer[index]"
                data-vv-scope="default"
                placeholder="Enter the answer"
              >  
              </HbTextField>
              <template v-if="leadAnswersOption[index]?.responseCheck">
                <p class="py-1 hb-text-light">Response</p>
                <p class="hb-font-body">{{ leadAnswersOption[index]?.response }}</p>
              </template>
            </hb-form>
          </template>

        </hb-expansion-panel>
        

      </v-expansion-panels>
    </v-form>
    
      <todo
        v-model="show_reminder"
        v-if="show_reminder"
        :title_text="'Create Task'"
        :btn_text="'Create'"
        :defaultAction="'Lead Follow up'"
        :disableAction="true"
        :contact_id="contact.id"
        :lease_id="lease.id"
        :event_type="true ? lease.id ? 'reservation_follow_up' : 'lead_follow_up' : ''"
      >
      </todo>

    
    <!-- <todo v-model="show_reminder"></todo> -->

    <hb-modal size="medium" title="Confirmation" v-model="duplicateContactConfirmationModal" confirmation show-help-link
      @close="doNotProceed()">
      <template v-slot:content>
        <div class="py-4 px-6">
          A contact with this phone number and email address already exists. If you proceed, a new contact will be created
          and your tenant will not be able to use their existing gate code for this rental.
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="primary" @click="goToNextStep('space')">Ok</hb-btn>
      </template>
    </hb-modal>
    <hb-modal size="medium" title="Warning" v-model="warningModal" confirmation show-help-link :footerCancelOption="false">
      <template v-slot:content>
        <div class="px-6 py-4 pb-2">
          {{ warningModalMessage }}
        </div>
      </template>
      <template v-slot:right-actions>
      <hb-btn @click="warningModal=false">I Understand</hb-btn>
      </template>  
    </hb-modal>

    <rent-as-business
            v-model="showRentAsBusinessModal" 
            v-if="showRentAsBusinessModal"
            :currentContactType="old_contact_type"
            :newContactType="new_contact_type"
            @close="closeRentAsBusinessModal()"
            @ok="closeRentAsBusinessModal(true)"
        >
    </rent-as-business>

  </div>

</template>
<script type="text/babel">

    import api from '../../../assets/api.js';
    import Status from './../Messages.vue';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import AddressAutofill from '../../autofill/AddressAutofill.vue';
    import { mapGetters, mapActions } from 'vuex';
    import Todo from './../Todo.vue';
    import moment from 'moment';
    import { parsePhoneNumber } from 'libphonenumber-js';
    import { EventBus } from "../../../EventBus.js";
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import { validateEmailPhoneMixin } from '../../../mixins/validateEmailPhoneMixin';
    import { countryStateCityMixin } from '../../../mixins/countryStateCityMixin';
    import PHONE from "@/constants/phone.js";
    import RentAsBusiness from './Modal/RentAsBusinessModal.vue';
    import LeadInteractionOptions from './LeadInteractionOptions.vue';
    import LeadOnBoard from './LeadOnBoard.vue';

    export default {
        name: 'LeadData',
        mixins: [notificationMixin, validateEmailPhoneMixin,countryStateCityMixin],
        components: {
            HbDatePicker,
            Todo,
            RentAsBusiness,
            LeadInteractionOptions,
            LeadOnBoard,
            Status,
            AddressAutofill
        },
        props: ['selected', 'selectedLeadId','contact_type', 'lease_id', 'context', "loading", "showSpacesList", "unKnownNumber","charm_property_id","location","show_reminder","property_id","disableLeadQuestions","loadLeadScreen"],
        data: () => ({
          autofillData: {},
          length_of_stays: ['1-3 Months', '3-6 Months', '6-12 Months', '1+ Year'],
          currentDate: '',
          times: [],
          panel: [0,1,2],
          valid: false,
          leadAnswersOption: [],
          leadQuestionAnswer:[],
          leadQuestions:'',
          Access:{
              id: '',
              pin: '',
              property_id: ''
          },
          form: {
              contact: {},
              lead: {}
          },
          move_in_date: null,
          length_of_stay: null,
          sources:[
              "Company Website",
              "Online Search",
              "Referral",
              "Social Media",
              "Walk-In",
              "Sparefoot",
              "Storagefront",
              "Storelocal",
              "Tenant Website",
              "Call Center"
          ],
          methods:[
              "Phone Call",
              "Email",
              "Website",
              "Walk In",
          ],
          found:[],
          email_types: ['Primary', 'Work', 'Other'],
          phone_types: ['Phone','Cell','Home','Work','Fax','Other'],
          rent_as_company: '-1',
          secondary_contact: '-1',
          military: '-1',
          interaction:{
              activity_object_id:'',
              content: '',
              date: null,
              hh: '',
              mm: '',
              ampm: 'AM',
          },
          todo: {
              add: false,
              date: null,
              time: '',
              hh: '05',
              mm: '00',
              ampm: 'PM',
              details: ''
          },
          remindTime: false,
          tenant:{
              id: null,
              contact_id: '',
              lease_id: '',
              created_at: '',
              type: '',
              sort: ''
          },
          // show_reminder: false,
          showAlert: true,
          countryCodesList: PHONE.COUNTRY_CODES,
          invalidPhones: [],
          savingInProgress: false,
          duplicateContactConfirmationModal: false,
          isRentAsBusiness: false,
          showRentAsBusinessModal: false,
          interactionType:['walk in','call','etc...'],
          selectField:'',
          scriptContent:'added scripts will be shown here  ',
          hideScript: true,
          selectedInteractionOption:'',
          selectedSourceOption:'',
          selectedChildOption:'',
          floor_items: [],
          size_items: [],
          amenities_items:[],
          filterSpace: {
            type: '',
            size: '',
            price: '',
            floor: '',
            amenities: '',
          },
          textField1:'',
          defaultCountry: 'United States',
          emailWarningShown: false,
          phoneWarningShown: false,
          addressWarningShown: false,       
          warningModal:false,
          warningModalType: '',
          warningModalMessage: '',
          initialEmail:'',
          initialPhones: [],
          initialAddresses: [],
          editWarningMessage: "You're trying to change existing contact's {} information. As this contact is already a tenant, changing this information may result in information mismatch in the Lease Agreement and Invoice, to solve that you might have to manually regenerate the mentioned documents.",
          showCharmOption:false,
          response:''
        }),
        async created(){
          const generalScope = 'general'
          this.setCountriesListEvent()
          this.selectField = this.interactionType[0]
            if(this.contact && this.contact.id){
                this.form.contact = JSON.parse(JSON.stringify(this.contact));
                this.form.contact.Phones = this.contact.Phones.map(p => {
                  let ph =p.phone && p.phone.length === 10? `+1${p.phone}`: `+${p.phone}`;
                  return this.phoneNumberParser(ph,p);
                })
                this.isRentAsBusiness = this.contact.rent_as_business;
                if(this.isRentAsBusiness) {
                  this.phone_types.unshift("Office Phone");
                  if(this.form.contact.Phones.length) this.form.contact.Phones[0].type = 'Office Phone';
                }
            } else {
                this.resetContacts(this.unKnownNumber);
            }

            if(this.lead && this.lead.id){
                this.form.lead = JSON.parse(JSON.stringify(this.lead));
                this.form.lead.property_id = this.lead.property_id;
                this.validateProperty();
                if(this.lead?.move_in_date){
                  this.form.lead.move_in_date = moment(this.lead?.move_in_date).format('YYYY-MM-DD');
                }
                else if(this.form.lead.move_in_date == "0000-00-00" || this.form.lead.move_in_date == null || this.form.lead.move_in_date) {
                  this.form.lead.move_in_date = moment().format('YYYY-MM-DD');
                }
            } else {
                this.resetLead();

                if(this.property.id){
                    this.form.lead.property_id = this.property.id;
                } else if(this.properties.length === 1){
                    this.form.lead.property_id = this.properties[0].id;
                }

              
            }
            //added by bct-property-id
            if(this.charm_property_id){
              this.form.lead.property_id  = this.charm_property_id; 
            }

            if(!this.form.contact.Phones.length){
                this.addPhone('primary');
            }
            if(this.contact.id){
                this.form.contact = JSON.parse(JSON.stringify(this.contact));
                this.prevContacts = JSON.parse(JSON.stringify(this.contact)); 
                this.form.contact.driver_license_country = this.setCountryNameEvent(this.contact.driver_license_country)
                this.form.contact.driver_license_state = this.contact.driver_license_state || ''

                this.setDLStatesListEvent(this.form.contact.driver_license_country)
                //  reset address if its empty array
                if(this.form.contact?.Addresses?.length == 0){
                  this.resetAddresses()
                }

                 this.form.contact.Addresses.map((value, i) => {
                    const fieldName = `city${i}`
                    const targetValue = {target:{value: this.form.contact.Addresses[i].Address.city, dataset: {vvScope: generalScope, vvName: fieldName}}}
                    this.form.contact.Addresses[i].Address.country = this.setCountryNameEvent(value.Address.country)

                    this.setStatesListEvent(this.form.contact.Addresses[i].Address.country, i)
                    if(!value.Address.state){
                        this.setCountryCitiesListEvent(this.form.contact.Addresses[i].Address.country, i)
                    }else{
                        this.setCitiesListEvent(this.form.contact.Addresses[i].Address.country, value.Address.state, i)
                    }
                    this.setCityList({event: targetValue, country: this.form.contact.Addresses[i].Address.country, state: value.Address.state, cityList: this.cityListMixin, index: i})

                })
                 
                this.form.contact.Phones = this.contact.Phones.map(p => {
                    try {
                      var parseEditedPhone = parsePhoneNumber('+' + p.phone);
                    }  catch(err){ 
                    }

                  if(!parseEditedPhone){
                    return {
                      id: p.id,
                      sms: p.sms,
                      phone: p.phone,
                      code: '1',
                      number: p.phone,
                      type: p.type
                    }
                  } else {
                    return {
                      id: p.id,
                      sms: p.sms,
                      phone: p.phone,
                      code: parseEditedPhone.countryCallingCode,
                      number: parseEditedPhone.nationalNumber,
                      type: p.type
                    }
                  }

                });
                this.form.contact.Military = this.form.contact.Military || {};
                this.form.contact.Business = this.form.contact.Business || {};
                this.form.contact.Business.Address = this.form.contact.Business.Address || {};
                this.form.contact.Relationships = this.form.contact.Relationships || [];
                this.form.contact.Representative = this.form.contact.Representative || {};
                this.isRentAsBusiness = this.form.contact.rent_as_business;
                if(this.isRentAsBusiness) {
                  this.phone_types.unshift("Office Phone");
                  this.form.contact.Phones[0].type = 'Office Phone';
                }
                this.form.vehicle = this.contact?.Vehicles?.[0] || {};
                this.form.vehicle.registered_owner = this.form.vehicle.registered_owner || {};
                this.form.vehicle.legal_owner = this.form.vehicle.legal_owner || {};
            } else {
                this.resetContact();
            }
            this.showPhoneNumber();
            await this.$store.dispatch('filterStore/getPromotions');
            // await this.$store.dispatch('filterStore/getCategories');

            this.currentDate = moment().format('YYYY-MM-DD');


            let timesArray = [];
            new Array(24).fill().forEach((acc, index) => {
                timesArray.push(moment( {hour: index} ).format('h:mm a'));
                timesArray.push(moment({ hour: index, minute: 15 }).format('h:mm a'));
                timesArray.push(moment({ hour: index, minute: 30 }).format('h:mm a'));
                timesArray.push(moment({ hour: index, minute: 45 }).format('h:mm a'));
            });
            
            this.times = timesArray;
            this.todo.time = '5:00 pm';
            this.fetchScripts() //Temporarily commented, needed for Lead flow revamp

            this.$emit("update:loading", false);
          },
          mounted() {
            this.initialEmail = this.form.contact.email; 
            this.initialPhones = JSON.parse(JSON.stringify(this.form.contact.Phones));
            this.initialAddresses = JSON.parse(JSON.stringify(this.form.contact.Addresses));
            setTimeout(() => {
              this.$store.dispatch('charmCallStore/setLoadingAction', {"status": false, "eventStatus": "lead-button-clicked"})
            }, 2000);
            if(this.lead?.property_id) this.form.lead.property_id = this.lead?.property_id;
            // else this.form.lead.property_id = this.properties[0].id;
            // EventBus.$on('propertySelected', (propertyId) => {
            //   this.form.lead.property_id = propertyId;
            // });
            EventBus.$on('unitsComponentMounted',this.syncProperty)
            this.getLeadQuestions();
          },
        computed:{
            is_loading(){
                return this.loadingName === this.$options.name
            },
            reservation_services(){
                return this.reservation.Template.Services.filter(s => s.service_type === 'lease')
            },
            reservation_start(){
               return moment(this.reservation.Lease.start_date).format('MM/DD/YYYY');
            },
            reservation_category(){

              if(!this.reservation.Unit || !this.reservation.Unit.category_id) return;
              let cat = this.categories.find(c => c.id === this.reservation.Unit.category_id);
              if(!cat) return '';
              return cat.name;

            },
            reservation_promotion(){
              if(!this.reservation.Lease || !this.reservation.Lease.promotion_id) return;
              let promo = this.promotions.find(p => p.id ===this.reservation.Lease.promotion_id);
              if(!promo) return '';
              return promo.name;

            },
            contact: {
                get() { return this.$store.getters['onBoardingStore/contact'] } ,
                set(val){  return this.$store.commit('onBoardingStore/setContact', val ) }
              },
            lead: {
                get() { return this.$store.getters['onBoardingStore/lead'] } ,
                set(val){  return this.$store.commit('onBoardingStore/setLead', val ) }
            },
            new_contact_type(){
              return this.isRentAsBusiness ? 'Business' : 'Individual';
            },
            old_contact_type(){
              return this.new_contact_type === 'Business' ? 'Individual': 'Business';
            },
            ...mapGetters({
                property: 'onBoardingStore/property',
                properties: 'propertiesStore/filtered',
                categories: 'filterStore/unit_category',
                lease: 'onBoardingStore/lease',
                tenants: 'onBoardingStore/tenants',
                reservation: 'onBoardingStore/reservation',
                promotions: 'filterStore/promotions',
                configuration_unit: 'onBoardingStore/configuration_unit',
                loadingName: 'onBoardingStore/loadingName',
                activeCategory: 'filterStore/unit_active_category',
                isCharmSubscribedProperty: 'charmSubscriptionStore/isCharmSubscribedProperty',
                routePhoneNumber: 'onBoardingStore/route_phone_number'
            }),
            isReturningTenant(){
              return this.contact?.Leases?.length > 0;
            },
            isReturningContact(){
              return this.contact?.is_returning_contact;
            }
        },
        methods: {
            ...mapActions({
                getCategoriesForProperty: "filterStore/getCategoriesForProperty",
                getActiveCategory: "filterStore/getActiveCategory",
                addRoutePhoneNumber : 'onBoardingStore/addRoutePhoneNumber'
            }),
            setRentAsBusiness() {
              if(this.isRentAsBusiness == true) {
                this.phone_types.unshift("Office Phone");
                this.form.contact.Phones[0].type = 'Office Phone';
                this.form.contact.last = '';
              } else {
                const index = this.phone_types.indexOf("Office Phone");
                if (index > -1) {
                  this.phone_types.splice(index, 1);
                  this.form.contact.Phones[0].type = 'Cell';
                }
              }
            },
            showPhoneNumber(){
              if(!this.contact.id && this.routePhoneNumber.phone){
                if(this.routePhoneNumber.phone.length === 11){
                  var parsedNumber =  parsePhoneNumber('+' + this.routePhoneNumber.phone);
                }
                else{
                  var parsedNumber = parsePhoneNumber('+1' + this.routePhoneNumber.phone);
                }
                  this.form.contact={
                    Phones: [{
                    type: 'Cell',
                        code: parsedNumber.countryCallingCode,
                        number: parsedNumber.formatNational(),
                        phone: '',
                        sms: true,
                      }]
                  }
                this.checkValidPhone(this.form.contact.Phones[0].code, this.form.contact.Phones[0].number, 0);
                this.addRoutePhoneNumber({}); 
              }
            },
            closeRentAsBusinessModal(confirm = false) {
              this.showRentAsBusinessModal = false;
              if(!confirm)
                this.isRentAsBusiness = !this.isRentAsBusiness;
              else
                this.setRentAsBusiness();
            },
            async getSizes(){
                let r = await api.get(this, api.UNITS +  'options', { property_id: this.property.id   });
                this.size_items = r.options.map(o => o.width + "' x " + o.length + "'" );
            },
            async getFloors(){
                let r = await api.get(this, api.UNITS + 'floors', { property_id: this.property.id });
                this.floor_items= r.floors.map( o => o.name );
            },
            async getAmenities(){
                let r = await api.get(this, api.UNITS + 'amenities' + '/all', { property_id: this.property.id });
                this.amenities_items= r.amenities.map( o => o.name );
            },
            isFormFilled(){
              if(this.form.contact.email || this.form.contact.first || this.form.contact.last || this.form.contact.Phones[0]?.number )
                return true;
              return false;
            },
            displayRentAsBusinessModal(){
              let is_form_filled = this.isFormFilled();
              if(is_form_filled)
                this.showRentAsBusinessModal = true;
              else
                this.setRentAsBusiness();
            },
            validateEmailPhone(){
              // Purpose of this method is to check if either Email or Phone is added

              // This condition is to check if both are not added and returns true otherwise false
              if(!this.form.contact.email && !this.form.contact.Phones[0].number){
                return true
              }
              return false
            },
            async moveIn() {

            },
            async changeUnit() {
            },
            countSectionError(section) {
                if (!this.errors || !this.errors.items || !this.errors.items.length) return false;
                return this.errors.items.filter(i => i.scope === section).length;
            },

            addEmail() {
                this.form.contact.Email.push({
                    type: 'Primary',
                    email: ''
                })
            },
            removeEmail(i) {
                if (this.form.contact.Email.length > 1) {
                    this.form.contact.Email.splice(i, 1);
                }
            },
            addPhone() {
                this.form.contact.Phones.push({
                    type: 'Cell',
                    code: '1',
                    number: '',
                    phone: '',
                    sms: true,
                })
            },
            addSecondaryPhone(i) {
                this.form.contact.Relationships[i].Contact.Phones.push({
                    type: 'Phone',
                    phone: ''
                })
            },
            removePhone(type, i, j) {
                if (type === 'contact' && this.form.contact.Phones.length > 1) {
                    this.form.contact.Phones.splice(i, 1);
                }
                if (type === 'related' && this.form.contact.Relationships[i].Contact.Phones.length > 1) {
                    this.form.contact.Relationships[i].Contact.Phones.splice(j, 1);
                }
            },

            async saveLead(next = null,flow_name) {
                 this.showEmailPhoneValidation(this.validateEmailPhone(), this.errors)


                if (this.invalidPhones.length && this.errors.items.indexOf('Please enter valid phone number(s) before continuing.') === -1) {
                  this.errors.items.push('Please enter valid phone number(s) before continuing.');
                }
                
                if (!this.invalidPhones.length && this.errors.items.indexOf('Please enter valid phone number(s) before continuing.') >= 0){
                  var invalidPhoneIndex = this.errors.items.indexOf('Please enter valid phone number(s) before continuing.');
                  this.errors.items.splice(invalidPhoneIndex);
                }

                let general_status = await this.$validator.validateAll('general');
                let lead_status = await this.$validator.validateAll('lead');
                let todo_status = await this.$validator.validateAll('todo');

                if (this.invalidPhones.length || !lead_status || !general_status || !todo_status || this.validateEmailPhone()) {
                  this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                  return "LEAD_FORM_ERROR";
                }
                try {                  
                  this.savingInProgress = true;
                  if (this.form.contact.id) {
                    await this.confirmSaveLead(next,false,flow_name);
                  } else {
                    let isContactExist = await this.isContactExist();
                    if (isContactExist) {
                      this.$emit('isConfirmContactDialogVisible',{flag:true})
                      this.duplicateContactConfirmationModal = true;
                    } else {
                      await this.confirmSaveLead(next,false,flow_name);
                    }
                  }
                  this.savingInProgress = false;
                } catch (error) {
                  this.savingInProgress = false;
                }
              //   let contact = {
              //   company_id: this.form.contact.company_id,
              //   salutation: this.form.contact.salutation,
              //   first: this.form.contact.first,
              //   middle: this.form.contact.middle,
              //   last: this.form.contact.last,
              //   suffix: this.form.contact.suffix,
              //   company: this.form.contact.company,
              //   email: this.form.contact.email,
              //   rent_as_business: this.isRentAsBusiness
              // }
              // contact.Phones = this.form.contact.Phones.map(p => {
              //   return {
              //     id: p.id,
              //     type: p.type,
              //     phone: p.phone,
              //     sms: !!p.sms
              //   }
              // });
              // contact.Addresses = this.form.contact.Addresses;
                
              //   let lead = {
              //       property_id: this.form.lead.property_id,
              //       unit_id: this.form.lead.unit_id,
              //       category_id: this.form.lead.category_id,
              //       note: this.form.lead.note,
              //       content: this.form.lead.content,
              //       source: this.form.lead.source,
              //       // method:         this.form.lead.method,
              //       length_of_stay: this.form.lead.length_of_stay,
              //       move_in_date: this.form.lead.move_in_date,
              //   }

              //   try {
              //     
              //       let contact_id = await this.$store.dispatch('onBoardingStore/saveLead', {
              //           contact_id: this.form.contact.id,
              //           lead_id: this.form.lead.id,
              //           lead,
              //           contact
              //       });
              //       this.$emit('contact_saved', {
              //           contact_id: contact_id,
              //           next: next
              //       });
              //   } catch (err) {
              //     
              //      this.errorSet(this.$options.name, err)
              //   }

                this.$store.commit('onBoardingStore/setLoadingName', null); 

                this.savingInProgress = false;

            },
            doNotProceed() {
              this.$emit('isConfirmContactDialogVisible',{flag:false})
              this.duplicateContactConfirmationModal = false;
            },
            goToNextStep(next) {
              this.$emit('isConfirmContactDialogVisible',{flag:false})
              this.duplicateContactConfirmationModal = false;
              this.confirmSaveLead(next, true);
            },
            async isContactExist() {
              let emailContacts = await this.checkEmail();
              let phoneContacts = [];
              for (let i = 0; i < this.form.contact.Phones.length; i++) {
                let element = this.form.contact.Phones[i];
                let pc = await this.checkPhone(element.number, element.code,i);
                phoneContacts.push(...pc);
              }
              
              // log
              // let isFound = (phoneContacts.length + emailContacts.length) > 0;
              // first check email and phone is filled in form
              if(this.form?.contact?.Phones[0]?.number && this.form?.contact?.email){
                return phoneContacts.length > 0 && emailContacts.length > 0;
              }
              else if(this.form?.contact?.Phones[0].number){
                return (phoneContacts.length) > 0;
              }
              else if(this.form?.contact?.email){
                return (emailContacts.length) > 0;
              }else{
                return false;
              }
              // return (this.form?.contact?.Phones[0]?.number && this.form?.contact?.email && (phoneContacts.length + emailContacts.length > 0)) || (this.form?.contact?.Phones[0] && phoneContacts.length > 0) || (this.form?.contact?.email && emailContacts.length > 0);
              // let isFound = phoneContacts.length > 0 && emailContacts.length > 0;
              // return true;
            },
            async confirmSaveLead(next, createNewContact = false, flow_type) {
              let contact = {
                company_id: this.form.contact.company_id,
                salutation: this.form.contact.salutation,
                first: this.form.contact.first,
                middle: this.form.contact.middle,
                last: this.form.contact.last,
                suffix: this.form.contact.suffix,
                company: this.form.contact.company,
                email: this.form.contact.email,
                rent_as_business: this.isRentAsBusiness
              }
              // if phone number is empty remove that from the form,
              contact.Phones = this.form.contact.Phones.filter(i => !!i.number).map(p => {
                  return {
                    id: p.id,
                    type: p.type,
                    phone: p.phone,
                    sms: !!p.sms
                  }
              });
              
              contact.Addresses = this.form.contact.Addresses;
              if (this.form.lead.move_in_date == "0000-00-00" || this.form.lead.move_in_date == null) {
                this.form.lead.move_in_date = moment().format('YYYY-MM-DD');
              }
              let lead = {
                property_id: this.form.lead.property_id,
                unit_id: this.configuration_unit.id,
                category_id: this.configuration_unit.category_id,
                note: this.form.lead.note,
                content: this.form.lead.content,
                source: this.form.lead.source,
                length_of_stay: this.form.lead.length_of_stay,
                move_in_date: this.form.lead.move_in_date,
                rent_as_business: this.isRentAsBusiness,
                lead_type: this.form.lead?.id ? this.form.lead?.lead_type : "inquiry",
                flow_type: flow_type,
                tracking: {}
              }
              if(next == "SPECIAL"){
                lead.lead_type = "pending"
              }
              if(createNewContact){
                lead.createNewContact = createNewContact
              }
              // check if its new lead
              if(!this.form?.lead?.id){
                let touchpoint = {
                  referrer_channel : this.selectedInteractionOption,
                  referrer_medium : this.selectedChildOption || this.selectedSourceOption,
                  referrer_source : "Offline",
                  platform_source : "Hummingbird",
                }
                lead.tracking = {
                  touchpoints: [touchpoint]
                }
                lead.interaction_initiated = this.selectedInteractionOption
                lead.source_type = this.selectedChildOption || this.selectedSourceOption
              }
              // If the selected lead is a retired lead, then make the lead status active.
              if (this.form.lead.id && this.form.lead.status == 'retired')
              {
                lead.status = ''
                lead.retire_reason = ''
              }
              try {
                this.savingInProgress = true;
                this.$store.commit('onBoardingStore/setLoadingName', this.$options.name);
                let contact_id = await this.$store.dispatch('onBoardingStore/saveLead', {
                  contact_id: this.form.contact.id,
                  lead_id: this.form.lead.id,
                  lead,
                  contact
                });
                this.$emit('contact_saved', { contact_id: contact_id, next: next });
                this.$store.commit('onBoardingStore/setLoadingName', null);
                this.savingInProgress = false;
              } catch (err) {
                this.errorSet(this.$options.name, err);
                this.$store.commit('onBoardingStore/setLoadingName', null);
                this.savingInProgress = false;
              }
            },

            async saveInteraction(contact_id) {

                //var occurred = moment.utc().format('YYYY-MM-DD HH:mm');

                var data = {
                    content: this.form.lead.content,
                    method: this.form.lead.method,
                    context: this.context,
                    property_id: this.form.lead.property_id,
                    unit_id: this.form.lead.unit_id,
                    created: moment.utc().format('YYYY-MM-DD HH:mm:ss')
                }

                if (this.todo.add) {


                    var hours = moment(this.todo.time, ["h:mm A"]).format("HH:mm:ss");

                    var start = moment(this.todo.date + ' ' + hours, 'YYYY-MM-DD HH:mm:ss');

                    data.Todo = {
                        start_date: start.utc().format('YYYY-MM-DD HH:mm:ss'),
                        end_date: start.utc().format('YYYY-MM-DD HH:mm:ss'),
                        details: this.todo.notes,
                    }
                }

                await api.post(this, api.CONTACTS + contact_id + '/interaction', data);

            },

            async setContactInfo(contact_id) {
                await this.$store.dispatch('getContactInfo', {contact_id: contact_id});
            },
            async checkEmail() {
              this.$emit('showFoundLoader',{showFoundLoader:true})
              this.form.contact.email = this.form.contact.email.toLowerCase().trim();
                if (this.form.contact.id || !this.form.contact.email.length){ 
                  this.$emit('found', { type: 'email', contacts: [] })
                this.$emit('showFoundLoader',{showFoundLoader:false})
                  return [];
                }
                let r = await api.get(this, api.CONTACTS + 'check', {
                    id: this.form.contact.id,
                    email: this.form.contact.email
                });

                if (r.contacts && r.contacts.length && !this.form.contact.id) {
                    this.found = r.contacts;
                } else {
                    this.found = [];
                }
                // if(this.found.length > 0) {
                this.$emit('found', { type: 'email', contacts: this.found })
              // }
              this.$emit('showFoundLoader',{showFoundLoader:false})
                return this.found;
            },

            async checkPhone(value, code, index) {
              if(index != 0) return []
              this.$emit('showFoundLoader',{showFoundLoader:true})
                if (this.form.contact.id || !this.form.contact.Phones.length || !value){
                  this.$emit('found', { type: 'phone', contacts: [] })
                  this.$emit('showFoundLoader',{showFoundLoader:false})
                  return [];
                }
                let r = await api.get(this, api.CONTACTS + 'check', {
                    id: this.form.contact.id,
                    phone: code + value.replace(/\D/g, '')
                });
                if (r.contacts && r.contacts.length && !this.form.contact.id) {
                    this.found = r.contacts || [];
                } else {
                    this.found = [];
                }
                // if(this.found.length > 0) {
                this.$emit('found', { type: 'phone', contacts: this.found })
              // }
              this.$emit('showFoundLoader',{showFoundLoader:false})
                return this.found;
            },

            async checkName() {
              this.$emit('showFoundLoader',{showFoundLoader:true})
                let last = !this.isRentAsBusiness ? this.form.contact.last : '';
                if (this.form.contact.id || !this.form.contact.first || (!last && !this.isRentAsBusiness)){
                  this.$emit('found', { type: 'name', contacts: [] })
                  this.$emit('showFoundLoader',{showFoundLoader:false})
                  return;
                }
                let r = await api.get(this, api.CONTACTS + 'check', {
                    contact_id: this.form.contact.id,
                    first: this.form.contact.first,
                    last: last
                });

                if (r.contacts && r.contacts.length && !this.form.contact.id) {
                    this.found = r.contacts;
                } else {
                    this.found = [];
                }
                // if(this.found.length > 0) {
                this.$emit('found', { type: 'name', contacts: this.found })
              // }
              this.$emit('showFoundLoader',{showFoundLoader:false})

            },
            resetLead() {
                this.$set(this.form, 'lead', {
                    id: null,
                    property_id: "",
                    unit_id: '',
                    category_id: '',
                    note: '',
                    content: '',
                    extras: {},
                    status: 'unread',
                    opened: 'null',
                    source: '',
                    method: '',
                    length_of_stay: '',
                    move_in_date: null,
                })
            },
            phoneNumberParser(phoneNo, phoneObj){
              const{phone,id,sms,type} = phoneObj || {};
              try {
                var parseEditedPhone = parsePhoneNumber(phoneNo);
              } catch(err) {}
              let parsedObj = {
                id,
                sms,
                phone,
                type
              }
              if(!parseEditedPhone){
                  parsedObj =  {
                    ...parsedObj,
                    code: '1',
                    number: '',
                  }
              } else {
                  parsedObj =  {
                    ...parsedObj,
                    code: parseEditedPhone.countryCallingCode,
                    number: parseEditedPhone.formatNational(),
                  }
              }
              return parsedObj;
            },
            resetContacts(unKnownNumber) {
              const data = {
                    first: '',
                    last: '',
                    email: '',
                    active_military: false,
                    Phones: [{
                        type: 'Cell',
                        code: '1',
                        number: '',
                        sms: true
                    }]
                }
                if(unKnownNumber && unKnownNumber.length){
                const parseEditedPhone = parsePhoneNumber(unKnownNumber);
                if(parseEditedPhone && Object.keys(parseEditedPhone).length){
                    data.Phones[0].code = parseEditedPhone.countryCallingCode;
                    data.Phones[0].number = parseEditedPhone.nationalNumber;
                }
              }
                this.$set(this.form, 'contact', data);
            },
            phoneMask(code) {
                switch (parseInt(code)) {
                    case 1:
                        return "(###) ###-####";
                    default:
                        return "";
                }
            },
            checkValidPhone(code, number, i) {

                if (code || number) {
                    try {
                        var parsedPhoneNumber = parsePhoneNumber('+' + code + number);
                    } catch (err) {}
                    var r = null;
                    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                        this.form.contact.Phones[i].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                        if (this.invalidPhones.indexOf(i) != -1) {
                            this.invalidPhones.splice(this.invalidPhones.indexOf(i), 1);
                        } else {
                            // WHAT IS THIS?
                            '';
                        }
                        r = true;
                    } else if ((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number.length > 0) {
                        if (this.invalidPhones.indexOf(i) != -1) {
                            // WHAT IS THIS?
                            '';
                        } else {
                            this.invalidPhones.push(i);
                        }
                        r = false;
                    } else {
                        if (this.invalidPhones.indexOf(i) != -1) {
                            this.invalidPhones.splice(this.invalidPhones.indexOf(i), 1);
                        }
                        r = true;

                    }
                }
                return r;
            },
            validateProperty() {
              let is_prop_exist = !! this.properties.find(property => property.id === this.lead.property_id);
              if(!is_prop_exist) this.form.lead.property_id = undefined
            },
            async fetchScripts(){
              let getRES;
              try {
                getRES = await this.getReq(
              api.LEADS + "properties/" +this.form?.lead?.property_id + "/lead-script/")
              } catch (error) {
                getRES="";
              }
              if(getRES?.length && getRES != '<p></p>' && !this.lead?.id){
                this.scriptContent = getRES;
                this.hideScript = false
              }
              else {
                const companyScript= await this.getReq(
                api.LEADS + "lead-script/");
                if(companyScript?.length && companyScript != '<p></p>' && !this.lead?.id){
                  this.scriptContent = companyScript;
                  this.hideScript = false
                } else {
                  this.hideScript = true
                }
              }
            },
            async getReq(path) {
            return api.get(this, path);
            },
            setInteractionType(data){
              this.selectedInteractionOption = data?.selectedInteractionOption ?? null
              this.selectedSourceOption = data?.selectedSourceOption ?? null
              this.selectedChildOption = data?.selectedChildOption ?? null
            },
            async getLeadQuestions(){
              this.leadQuestions = await api.get(this, api.LEADS + 'questionnaire');
            },
            // onPropertyChange(){
            //   this.$emit('leadPropertyChange',this.form.lead.property_id)
            // }
            updatePropertyId(pid){
              this.form.lead.property_id = pid;
            },
            async validateLeadForm(){
              this.showEmailPhoneValidation(this.validateEmailPhone(), this.errors)
              
                if (this.invalidPhones.length && this.errors.items.indexOf('Please enter valid phone number(s) before continuing.') === -1) {
                  this.errors.items.push('Please enter valid phone number(s) before continuing.');
                }
                
                if (!this.invalidPhones.length && this.errors.items.indexOf('Please enter valid phone number(s) before continuing.') >= 0){
                  var invalidPhoneIndex = this.errors.items.indexOf('Please enter valid phone number(s) before continuing.');
                  this.errors.items.splice(invalidPhoneIndex);
                }
                
                let general_status = await this.$validator.validateAll('general');
                let lead_status = await this.$validator.validateAll('lead');
                let todo_status = await this.$validator.validateAll('todo');

                if (this.invalidPhones.length || !lead_status || !general_status || !todo_status || this.validateEmailPhone()) {
                  this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                  return "LEAD_FORM_ERROR";
                }
            },
            async createJoinWaitList() {
              let validationResult = await this.validateLeadForm();
              if (validationResult == "LEAD_FORM_ERROR") {
                this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                return "LEAD_FORM_ERROR"
              }
              let contactExist = await this.isContactExist()
              let lead = {
                property_id: this.form.lead.property_id,
                unit_id: this.configuration_unit.id,
                note: this.form.lead.note,
                content: this.form.lead.content,
                source: this.form.lead.source,
                length_of_stay: this.form.lead.length_of_stay,
                move_in_date: this.form.lead.move_in_date,
                lead_type: "waitlist",
                category_id: this.configuration_unit?.category_id,
                contact_id: this.contact.id
              }
              let contact = {
                company_id: this.form.contact.company_id,
                salutation: this.form.contact.salutation,
                first: this.form.contact.first,
                middle: this.form.contact.middle,
                last: this.form.contact.last,
                suffix: this.form.contact.suffix,
                company: this.form.contact.company,
                email: this.form.contact.email,
                rent_as_business: this.isRentAsBusiness
              }
              contact.Phones = this.form.contact.Phones.map(p => {
                return {
                  id: p.id,
                  type: p.type,
                  phone: p.phone,
                  sms: !!p.sms
                }
              });
              if (contactExist) { lead.createNewContact = false }
              else { lead.createNewContact = true }
              try {
                if (this.lead.id) {
                  // update current lead
                  await api.put(this, api.LEADS + this.lead.id, lead)

                } else {
                  // create a new lead
                  await this.$store.dispatch('onBoardingStore/saveLead', {
                    contact_id: this.form.contact.id,
                    lead_id: this.form.lead.id,
                    lead,
                    contact
                  });
                }

              } catch (err) {
                this.errorSet(this.$options.name, err)
              }
            },
            resetContact(){
                this.$set(this.form, 'contact', {
                        first: '',
                        last: '',
                        email: '',
                        driver_license: '',
                        driver_license_state: '',
                        driver_license_city: '',
                        driver_license_country: '',
                        driver_license_exp: '',
                        company: '',
                        active_military: false,
                        have_secondary_contact: false,
                        Phones: [{
                          type: 'Cell',
                          code: '1',
                          number: '',
                          sms: true
                      }],
                      Military: {
                      },
                      Business: {
                          name: '',
                          phone_type: '',
                          phone: '',
                          Address: {
                                address: '',
                                address2: '',
                                city: '',
                                state: '',
                                country:'',
                                zip: ''
                          },
                      },
                      Addresses: [
                        {
                          id: null,
                          type: 'Home',
                          address_id: '',
                          Address: {
                            address: '',
                            address2: '',
                            city: '',
                            neighborhood: '',
                            lat: '',
                            lng: '',
                            state: '',
                            country: this.defaultCountry,
                            zip: ''
                          }
                        }
                    ],
                    Relationships: [
                      {
                          id: null,
                          type: '',
                          is_emergency: true,
                          is_cosigner: false,
                          is_military: false,
                          is_authorized: false,
                          is_lien_holder: false,
                          lease_id: '',
                          Contact: {
                              id: null,
                              first: '',
                              last: '',
                              email: '',
                              Phones: [{
                                  id: null,
                                  type: 'Cell',
                                  phone: '',
                                  sms: false
                              }],
                              Addresses: [
                                  {
                                      id: null,
                                      type: 'Home',
                                      address_id: '',
                                      Address: {
                                          address: '',
                                          address2: '',
                                          city: '',
                                          neighborhood: '',
                                          lat: '',
                                          lng: '',
                                          state: '',
                                          country:'',
                                          zip: ''
                                      }
                                  }
                              ],
                          },
                      }
                    ]
                });
                this.setStatesListEvent(this.defaultCountry, this.form.contact.Addresses.length-1)
                this.setCountryCitiesListEvent(this.defaultCountry, this.form.contact.Addresses.length-1)
            },
            resetAddresses(){
            this.form.contact.Addresses = [{
              id: null,
              type: 'Home',
              address_id: '',
              Address: {
                address: '',
                address2: '',
                city: '',
                neighborhood: '',
                lat: '',
                lng: '',
                state: '',
                country: this.defaultCountry,
                zip: ''
              }
            }]
            this.setStatesListEvent(this.defaultCountry, this.form.contact.Addresses.length-1)
            this.setCountryCitiesListEvent(this.defaultCountry, this.form.contact.Addresses.length-1)
          },
            addLocation(){
                this.form.contact.Addresses.push({
                    id: null,
                    type: 'Home',
                    address_id: '',
                    Address:{
                        address: '',
                        address2: '',
                        city: '',
                        country: this.defaultCountry,
                        neighborhood: '',
                        lat: '',
                        lng: '',
                        state: '',
                        zip: ''
                    }
                })
                this.setStatesListEvent(this.defaultCountry, this.form.contact.Addresses.length-1)
                this.setCountryCitiesListEvent(this.defaultCountry, this.form.contact.Addresses.length-1)
            },
            removeLocation(i){
                if(this.form.contact.Addresses.length > 1){
                    this.form.contact.Addresses.splice(i,1);
                }
            },
            setCountry(index){
                this.form.contact.Addresses[index].Address.state = ''
                this.form.contact.Addresses[index].Address.city = ''
                this.setStatesListEvent(this.form.contact.Addresses[index].Address.country, index)
                this.setCountryCitiesListEvent(this.form.contact.Addresses[index].Address.country, index)
            },
            setState(index){
                this.form.contact.Addresses[index].Address.city = ''
                this.setCitiesListEvent(this.form.contact.Addresses[index].Address.country, this.form.contact.Addresses[index].Address.state, index)
            },
            setCityList(payload){
                const {event, country, state, cityList} = payload
                const toValidate =  payload?.toValidate || false
                const index = payload?.index >= 0 ? payload?.index : -1
                const {value, dataset} = event.target
                if(!value) return
                if(toValidate) {
                    const name= `${dataset.vvScope}.${dataset.vvName}`
                    const isSpecialCharacter = this.validateCityName(value)
                    const cityValidation = this.errors.items.indexOf(name)

                    this.errors.items.splice(cityValidation);
                    if(isSpecialCharacter){
                        return this.errors.add({
                                    field: name,
                                    msg: 'Please add a valid city name.'
                                }) 
                    }
                }

                const cityName = value.toString()
                const cityData = (index >= 0) ? cityList[index] : cityList;
                const isCityExists = cityData.some((city) => city.toLowerCase() === cityName.toLowerCase())
                if(isCityExists) return

                if(index >= 0){
                    this.$set(cityList, index, [...cityData, value])
                }else{
                    cityList.push(value)
                }
                
                const countryData = this.countriesListMixinRaw.find(item => item.name === country);
                this.addMissingCity({
                    country_code: countryData.isoCode,
                    state_code: state,
                    city: value
                })
            },
            warningModalShow(type, message){
              this.warningModal = true;
              this.warningModalType = type;
              this.warningModalMessage = message;
            },
            addressDataSet(autofillData, i) {
              if (this.form.contact.Addresses[i]?.Address) {
                if (autofillData?.country) {
                  this.form.contact.Addresses[i].Address.country = autofillData.country;
                  this.setCountry(i)
                }
                if (autofillData?.state && this.stateListMixin?.[i]) {
                  for (let state of this.stateListMixin[i]) {
                    if (state.name === autofillData.state) {
                      this.form.contact.Addresses[i].Address.state = state.value;
                    }
                  }
                  this.setState(i)
                }
                if (autofillData?.city) {
                  this.form.contact.Addresses[i].Address.city = autofillData.city;
                }
                if (autofillData?.postalCode) this.form.contact.Addresses[i].Address.zip = autofillData.postalCode;
                if (autofillData?.address1) this.form.contact.Addresses[i].Address.address = autofillData.address1;
                if (autofillData?.apt) this.form.contact.Addresses[i].Address.address2 = autofillData.apt;
              }
            },
            resetForm() {
              // console.log("================================================================ reset form called");
              // this.resetFormFlag=true
              // // this.$emit('resetForm');
              // this.filterSpace = {
              //   type: '',
              //   size: '',
              //   price: '',
              //   floor: '',
              //   amenities: '',
              // }
              // this.leadQuestions = ''
              // this.leadAnswersOption = []
              // this.leadQuestionAnswer = []
              // this.resetContact()
              // this.form.contact['Phones'] = [{
              //           type: 'Cell',
              //           code: '1',
              //           number: '',
              //           sms: true
              //       }]
              // this.getLeadQuestions();
              // setTimeout(() => {
              //   this.resetFormFlag=false
              // }, 1500);
              // this.form = {
              //   contact: {},
              //   lead: {}
              // }
            }
            
        },
        watch:{
            routePhoneNumber: {
              handler(value){
                this.showPhoneNumber();
              },
              deep:true
              
            },
            "contact.id": async function(){
              this.form.contact = JSON.parse(JSON.stringify(this.contact));
              if(!this.contact?.Addresses?.length){
                this.resetAddresses();
              }
              this.form.contact.Phones = this.contact.Phones.map(p => this.phoneNumberParser(`+${p.phone}`, p));
              // this.form.contact.Phones = this.contact.Phones.map(p => this.phoneNumberParser(`+${p.phone}`, p));
              this.isRentAsBusiness = this.contact.rent_as_business;
              this.initialEmail = this.form.contact.email; 
              this.initialPhones = JSON.parse(JSON.stringify(this.form.contact.Phones));
              this.initialAddresses = JSON.parse(JSON.stringify(this.form.contact.Addresses));
              EventBus.$emit('contactDetailsUpdatedDuringRental')
            },
            "lead.id": async function(){
              if(this.lead && this.lead.id){
                  let originalLeadData = this.selectedLeadId ? this.contact.Leads.filter(item=> item.id == this.selectedLeadId) : this.lead
                    this.form.lead = JSON.parse(JSON.stringify(originalLeadData));
                    if(this.form.lead.move_in_date == "0000-00-00" || this.form.lead.move_in_date == null || this.form.lead.move_in_date) {
                      this.form.lead.move_in_date = moment().format('YYYY-MM-DD');
                    }
                } else {
                    this.resetLead();
                    if(this.property.id){
                        this.form.lead.property_id = this.property.id;
                    } else if(this.properties.length === 1){
                        this.form.lead.property_id = this.properties[0].id;
                    }
                }
                //added by bct-property-id
                if(this.charm_property_id){
                  this.form.lead.property_id  = this.charm_property_id; 
                }
            },
            "form.lead.property_id": async function(){
              // if the properties length is one then set that property id
              if(this.properties.length === 1){
                this.form.lead.property_id = this.properties[0].id;
              }
                if(!this.form.lead.property_id) return;
                //added by bct-property-id
                this.fetchScripts() //Temporarily commented, needed for Lead flow revamp
                if(this.charm_property_id){
                  this.form.lead.property_id  = this.charm_property_id; 
                }
                await this.getCategoriesForProperty({ property_id: this.form.lead.property_id });

                await this.getActiveCategory({id: this.form.lead.category_id});
                this.form.lead.category_id = this.activeCategory ? this.activeCategory.id : null;

                let property = this.properties.find(p => p.id === this.form.lead.property_id);
                await this.$store.dispatch('onBoardingStore/getPropertyInfo', property);

                await this.getFloors();
                await this.getSizes();
                await this.getAmenities();
            },
            "property_id": function(){
              this.form.lead.property_id = this.property_id;
            },
            loading() {
              if(!this.loading && this.showSpacesList){
                this.saveLead('space');
              }
            },
            "property.id"(){
              this.form.lead.property_id = this.property.id;
              // update dropdown for lead-interaction options if charm is enabled for the property.
              if(this.property?.id) { this.showCharmOption =   this.isCharmSubscribedProperty(this.property.id)?true:false };


            },
            filterSpace: {
              deep: true,
              handler(value){
                EventBus.$emit('lead-question-space-filter', this.filterSpace)
              }
            },
            'form.contact.email'(newEmail, oldEmail){
              if(this.initialEmail && !this.emailWarningShown && newEmail !== oldEmail && newEmail !== this.initialEmail && this.isReturningTenant){
                this.emailWarningShown = true
                this.warningModalShow("Email",this.editWarningMessage.replace("{}","Email"))
                // this.warningModalShow('Email',"Hark! Thou hast altered thine electronic missive. Pray, review it with great care.")

                // alert('Hark! Thou hast altered thine electronic missive. Pray, review it with great care.');
              }
            },
            'form.contact.Phones': {
              handler(newPhones) {
                if (!this.phoneWarningShown && this.isReturningTenant) {
                  const initialPhoneNumbers = this.initialPhones.map(phone => phone.number.replace(/[()\- ]/ig,''));
                  const hasModifications = newPhones.some((phone, index) => {
                    // Check if the phone number exists in the initial array and has been modified
                    return !initialPhoneNumbers.includes(phone.number.replace(/[()\- ]/ig,''));
                  });
                  if (hasModifications) {
                    this.phoneWarningShown = true;
                    this.warningModalShow("Phone",this.editWarningMessage.replace("{}","Phone"));
                  }
                }
              },
              deep: true
            },
            'form.contact.Addresses': {
              handler(newAddresses) {
                if (!this.addressWarningShown && this.isReturningTenant) {
                  const hasModifications = newAddresses.some((address, index) => {
                    // Check if the address exists in the initial array and if any specified property has been modified
                    
                    const initialAddress = this.initialAddresses[index];
                    if (!initialAddress) return false; // Skip comparison if it's a new address
                    return address.Address.address !== initialAddress.Address.address ||
                      address.Address.address2 !== initialAddress.Address.address2 ||
                      address.Address.city !== initialAddress.Address.city ||
                      address.Address.zip !== initialAddress.Address.zip ||
                      address.Address.country !== initialAddress.Address.country ||
                      address.Address.state !== initialAddress.Address.state;
                  });
                  if (hasModifications) {
                    this.addressWarningShown = true;
                    this.warningModalShow("Address",this.editWarningMessage.replace("{}","Address"));
                  }
                }
              },
              deep: true
            },
            'contact':{
              deep: true,
              handler(){
                this.form.contact = JSON.parse(JSON.stringify(this.contact));
                if (!this.contact?.Addresses?.length) {
                  this.resetAddresses();
                }
                this.form.contact.Phones = this.contact.Phones.map(p => this.phoneNumberParser(`+${p.phone}`, p));
                this.isRentAsBusiness = this.contact.rent_as_business;
                if(this.isRentAsBusiness && !this.phone_types.includes('Office Phone')) {
                  this.phone_types.unshift("Office Phone");
                  if(this.form.contact.Phones.length) this.form.contact.Phones[0].type = 'Office Phone';
                }
              }
            }
            },
          }

</script>

<style scoped>
    .card-title-text{
      color: rgb(35, 106, 188);
      font-size: 18px;
      font-weight: 500;
    }
    .reserved-status-badge{
      color: #fa983a;
      border-color: #fa983a;
    }
    .active-status-badge{
      color: #02AD0F;
      border-color: #02AD0F;
    }
    .top--5px{
      position: relative;
      top: -5px;
    }
    .move-in-label{
      font-size: 12px;
      color:rgba(0, 0, 0, 0.6);
    }
    .b-radius-5px{
      border-radius: 5px;
    }
    .fw-500{
      font-weight: 500;
    }
    .list-style-override{
      width: 130%;
    }
    .list-style-override >>> ul{
    list-style-type: disc !important;
  }
    .list-style-override >>> a{
    color: #00848e !important;
  }

</style>

<style lang="scss" scoped>

  .lease-section{
    padding: 20px 50px 20px 50px;
    background: #F9FAFB;
  }

  // .lease-section .new-tenant-walkthrough-footer{
  //   padding: 3px 0 3px 0;
  //   background: #FFFFFF;
  //   border-top: 1px solid #e2e2e2;
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  //   z-index: 100;
  //   left: 0;
  //   right: 0;
  // }

  .reserve-summ-drop.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
      background: transparent;
      padding: 0 !important;
  }
  .script-description{
    font-size: 12px;
    text-align: left;
    color: rgba(99, 115, 129, 1);
    margin-bottom: 10px;
  }

  .lease-section >>> .hb-aviary-form-padding-content {
    padding-bottom: 3px;
  }
  // .lead-Title-class{
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: space-between;
  // }

</style>
